import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const containerStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid rgb(207, 207, 207)',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const BatchEditConfirmationModal = ({ show, loading, error, batches, close, confirm }) => {

  useEffect(() => {
    if (show && !loading && !error && batches.length === 0) {
      // If there's nothing to confirm, just update the batch
      confirm();
    }
  }, [show, loading, error, batches, confirm]);

  if (loading) {
    return (
      <Modal open={show}>
        <Box sx={containerStyles}>
          <Stack alignItems="center" justifyContent="center" spacing={2} direction="column">
            <CircularProgress size={40} />
          </Stack>
        </Box>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal open={show}>
        <Box sx={containerStyles}>
          <Stack alignItems="center" justifyContent="center" spacing={2} direction="column">
            <Typography
              align="center"
              variant="h5"
              component="div"
              className="edit-modal__sub-heading"
            >
              Failed to load batch children to verify!
            </Typography>
            <Box>
              <Button
                onClick={close}
                color="primary"
                variant="contained"
              >
                OK
              </Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={show}>
      <Box sx={containerStyles}>
        {
          batches.length > 0 &&
            <Stack alignItems="center" justifyContent="center" spacing={2} direction="column">
              <Box>
                <WarningIcon sx={{
                  color: 'rgb(255,167,0)',
                  fontSize: '78px',
                }} />
              </Box>
              <Typography variant="h3" component="div">
                Warning!
              </Typography>
              <Typography
                align="center"
                variant="h5"
                component="div"
                className="edit-modal__sub-heading"
                sx={{ fontWeight: 'bold !important', margin: '8px 0px 16px !important' }}
              >
                Editing this batch will affect other batches
              </Typography>
              These batches need to be verified
              <Box sx={{maxHeight: '350px', overflow: 'hidden', overflowY: 'auto' }}>
                <Grid container spacing={2} justifyContent="flex-start" direction="column" alignItems="center">
                  {
                    batches.length === 0 && (<Grid item xs={12} sx={{ textAlign: 'center' }}>No batches to verify!</Grid>)
                  }
                  {
                    batches.length > 0 && batches.map((b) => (
                      <React.Fragment key={b.id}>
                        <Grid item xs={6} sx={{ textAlign: 'center' }}>{b.tank_name}</Grid>
                        <Grid item xs={6} sx={{ textAlign: 'center' }}>{b.batch_number}</Grid>
                      </React.Fragment>
                    ))
                  }
                </Grid>
              </Box>
              <Stack direction="row" justifyContent="space-between" sx={{ width: '270px' }}>
                <Box>
                  <Button
                    onClick={confirm}
                    color="primary"
                    variant="contained"
                  >
                    CONFIRM
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={close}
                    color="secondary"
                    variant="contained"
                  >
                    CANCEL
                  </Button>
                </Box>
              </Stack>
            </Stack>
        }
      </Box>
    </Modal>
  );
};

BatchEditConfirmationModal.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  batches: PropTypes.array,
  error: PropTypes.bool,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

BatchEditConfirmationModal.defaultProps = {
  show: false,
  loading: false,
  error: false,
};

export default BatchEditConfirmationModal;
