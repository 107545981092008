import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Stack } from '@mui/material';

import UserMenu from '../UserMenu/UserMenu';

import firebase from '../../static/firebase';
import NesteLogo from '../../static/neste-logo.svg';

function ApplicationBar(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, common } = props;
  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'USER_LOG_OUT' });
        navigate('/');
      });
  };

  return user ? (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" enableColorOnDark color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ mr: 2 }}
            onClick={() => {
              dispatch({
                type: 'TOGGLE_DRAWER',
                value: !common.drawerToggle,
              });
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src={NesteLogo} alt="" id="neste-logo" style={{ width: '150px' }} />
          <Typography
            id="jig_title-text"
            variant="h5"
            component="h5"
            sx={{
              flexGrow: 1,
              color: '#6799DB',
              marginLeft: '10px',
            }}
          >
            {t('jig_title', 'JIG Traceability')}
          </Typography>
          <Stack direction="row" spacing={1}>
            <UserMenu doLogOut={signOut} />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  ) : null;
}

function mapStateToProps(state) {
  const { user, common } = state;
  return { user: user.currentUser, common };
}

ApplicationBar.propTypes = {
  user: PropTypes.object,
  common: PropTypes.shape({}),
};

export default connect(mapStateToProps)(ApplicationBar);
