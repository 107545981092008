import { createTheme } from '@mui/material';

const jigTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: 0,
          minWidth: '120px',
          // minHeight: '40px',
        },
        containedPrimary: {
          backgroundColor: '#62BB21',
          '&:hover': {
            backgroundColor: '#478619',
          },
        },
        containedSecondary: {
          backgroundColor: '#003C96',
          '&:hover': {
            backgroundColor: '#06316F',
          },
        },
        textSecondary: {
          color: '#003c96',
          '&:hover': {
            color: '#06316f',
            backgroundColor: 'rgba(0, 60, 150, 0.04)',
          },
        },
        outlinedSecondary: {
          color: '#003c96',
          borderColor: '#003c96',
          '&:hover': {
            color: '#06316f',
            backgroundColor: 'rgba(0, 60, 150, 0.04)',
            borderColor: '#06316f',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#62BB21',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#62BB21',
        },
      },
    },
    MuiLink: {
      variants: [
        {
          props: { variant: 'document' },
          style: {
            color: '#003c96',
            textDecoration: 'none',
            fontWeight: 'bold',
            '&:hover': {
              color: '#06316f',
            },
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: '#003c96',
          '&.Mui-checked': {
            color: '#003c96',
          },
          '&:hover': {
            color: '#06316f',
            backgroundColor: 'rgba(0, 60, 150, 0.04)',
          },
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: '#D2E1F5',
          fontWeight: 'bold',
          color: '#003C96',
          '& .MuiTableCell-root': {
            backgroundColor: '#D2E1F5',
            fontWeight: 'bold',
            color: '#003C96',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              color: '#003c96',
              '& + .MuiSwitch-track': {
                backgroundColor: '#003c96',
              },
            },
          },
        },
      },
    },
  },
  palette: {
    nesteBlue: {
      main: '#003c96',
      darker: '#06316f',
    },
    nesteGreen: {
      main: '#62BB21',
      darker: '#478619',
    },
    text: {
      primary: 'rgb(51, 51, 51)',
      secondary: 'rgb(102, 102, 102)',
      highlight: 'rgb(98, 187, 33)',
      link: 'rgb(0, 60, 150)',
    },
  },
});

export default jigTheme;
