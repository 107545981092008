import manualService from '../../services/manualService';

export const getManualLink = () => (dispatch, getStore) => {
  const { common } = getStore();
  if (Date.now() - common.manualUpdated < 1000 * 60 * 50 || common.manualLoading) {
    // No need to do anything if the link is less than 50 minutes old
    return;
  }
  
  dispatch({ type: 'COMMON_SET_MANUAL_LOADING', value: true });
  manualService.getManualLink()
    .then((response) => {
      dispatch({
        type: 'COMMON_SET_MANUAL_LINK',
        value: response.data.manual_link,
      });
    })
    .catch((error) => {
      dispatch({ type: 'COMMON_SET_MANUAL_ERROR' });
    })
    .finally(() => dispatch({ type: 'COMMON_SET_MANUAL_LOADING', value: false }));
};

export const updateManual = (file) => (dispatch) => {
  dispatch({ type: 'COMMON_SET_MANUAL_UPDATE_LOADING', value: true });
  manualService.updateManual(file)
    .then((response) => {
      dispatch({
        type: 'COMMON_INVALIDATE_MANUAL',
      });
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'success',
          message: 'User manual updated successfully!',
        },
      });
    })
    .catch((error) => {
      dispatch({ type: 'COMMON_SET_MANUAL_UPDATE_ERROR' });
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'error',
          message: 'User manual update failed!',
        },
      });
    })
    .finally(() => dispatch({ type: 'COMMON_SET_MANUAL_UPDATE_LOADING', value: false }));
};
