import moment from 'moment';

const emptyBatch = {
  id: undefined,
  client_id: '', // Just used client side, to make sure react can tell the different sub batches apart. Do not update after creation.
  tank_id: -1,
  location_name: '',
  date: moment().format('YYYY-MM-DD'),
  batch_number: '',
  report_number: '',
  composition_doc_link: '',
  composition_doc_name: '',
  coa_doc_link: '',
  coa_doc_name: '',
  country_of_origin_id: -1,
  radiocarbon_doc_link: '',
  radiocarbon_doc_name: '',
  calcblend_doc_link: '',
  calcblend_doc_name: '',
  rc_doc_link: '',
  rc_doc_name: '',
  coq_doc_link: '',
  coq_doc_name: '',
  coo_doc_link: '',
  coo_doc_name: '', 
  tank_release_doc_link: '',
  tank_release_doc_name: '', 
  volume_cubic_meter_15c: 0,
  volume_original_scale: 'm3_15c',
  density_kg_m3_15c: 0,
  density_original_scale: 'kg_m3_15c',
  vessel_name: '',
  updated_at: '',
  updated_by: '',
  is_origin_batch: false,
  is_component_batch: false,
  isDirty: false,
};

/*
const child = {
  id: 0,
  tank_name: '',
  batch_number: '',
}
*/

export const initialState = {
  batch: {
    ...emptyBatch,
    subBatches: [],
  },
  childrenLoading: false,
  childrenError: false,
  children: [],
};

const generateDisplayId = () => {
  const arr = new Uint8Array(10);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, (dec) => dec.toString(16).padStart(2, 0)).join('');
};

const createEditReducer = (state = initialState, { type, value }) => {
  switch (type) {
  case 'EDIT_CLEAR':
    return { ...initialState };
  case 'EDIT_SET_DATA': {
    if (!value) {
      return state;
    }
    let subBatches = [];
    if (value.origin_batches) {
      subBatches = subBatches.concat(value.origin_batches.map(ob => ({
        ...ob,
        is_origin_batch: true,
        client_id: generateDisplayId(),
      })));
    }
    if (value.component_batches) {
      subBatches = subBatches.concat(value.component_batches.map(cb => ({
        ...cb,
        is_component_batch: true,
        client_id: generateDisplayId(),
      })));
    }
    subBatches = subBatches.map((sb) => {
      if (sb.composition_doc_link && !sb.composition_doc_name) {
        sb.composition_doc_name = sb.composition_doc_link;
      }
      if (sb.coa_doc_link && !sb.coa_doc_name) {
        sb.coa_doc_name = sb.coa_doc_link;
      }
      if (sb.radiocarbon_doc_link && !sb.radiocarbon_doc_name) {
        sb.radiocarbon_doc_name = sb.radiocarbon_doc_link;
      }
      if (sb.calcblend_doc_link && !sb.calcblend_doc_name) {
        sb.calcblend_doc_name = sb.calcblend_doc_link;
      }
      if (sb.rc_doc_link && !sb.rc_doc_name) {
        sb.rc_doc_name = sb.rc_doc_link;
      }
      if (sb.coq_doc_link && !sb.coq_doc_name) {
        sb.coq_doc_name = sb.coq_doc_link;
      }
      if (sb.coo_doc_link && !sb.coo_doc_name) {
        sb.coo_doc_name = sb.coo_doc_link;
      }
      if (sb.tank_release_doc_link && !sb.tank_release_doc_name) {
        sb.tank_release_doc_name = sb.tank_release_doc_link;
      }

      return sb;
    });
    const newBatch = {
      ...value,
      client_id: generateDisplayId(),
      subBatches,
    };
    delete newBatch.component_batches;
    delete newBatch.origin_batches;
    return {
      ...state,
      batch: newBatch,
    };
  }
  case 'EDIT_UPDATE_DATA':
    return {
      ...state,
      batch: {
        ...state.batch,
        ...value,
        isDirty: state.batch.density_kg_m3_15c !== value.density_kg_m3_15c || state.batch.volume_original_scale !== value.volume_original_scale,
      },
    };
  case 'EDIT_ADD_SUB_BATCH':
    return {
      ...state,
      batch: {
        ...state.batch,
        subBatches: state.batch.subBatches.concat([{
          ...emptyBatch,
          client_id: generateDisplayId(),
        }]),
      },
    };
  case 'EDIT_REMOVE_SUB_BATCH': {
    const newSubBatches = [...state.batch.subBatches];
    newSubBatches.splice(value, 1);
    return {
      ...state,
      batch: {
        ...state.batch,
        subBatches: newSubBatches,
      },
    };
  }
  case 'EDIT_UPDATE_SUB_BATCH': {
    const newSubBatches = [...state.batch.subBatches];
    const newSubBatch = {
      ...newSubBatches[value.index],
      ...value.batch,
    };
    newSubBatches.splice(value.index, 1, newSubBatch);
    return {
      ...state,
      batch: {
        ...state.batch,
        subBatches: newSubBatches,
      },
    };
  }
  case 'EDIT_SET_CHILDREN_LOADING':
    return {
      ...state,
      childrenLoading: value,
    };
  case 'EDIT_SET_CHILDREN_ERROR':
    return {
      ...state,
      childrenError: value,
    };
  case 'EDIT_SET_CHILDREN':
    return {
      ...state,
      childrenError: false,
      children: value,
    };
  default:
    return state;
  }
};

export default createEditReducer;
