import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Divider, Typography } from '@mui/material';

const dividerStyle = {
  borderBottomWidth: '2px',
  borderColor: 'rgb(195, 200, 200)',
};

const ViewContainer = ({ children, title, topMargin }) => {
  return (
    <Box>
      <Box sx={{ margin: 3 }}>
        { typeof title === 'string' ? (
          <Typography data-testid="jig-view__title" variant="h4" component="h4" color="text.secondary">
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
      <Divider sx={dividerStyle} />
      <Box 
        sx={{
          marginTop: topMargin ? 6 : 0,
          marginLeft: 2,
          marginRight: 2,
          marginBottom: 6,
        }}
      >
        { children }
      </Box>
    </Box>
  );
};

ViewContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  topMargin: PropTypes.bool,
};

ViewContainer.defaultProps = {
  topMargin: true,
};


export default ViewContainer;
