import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RedirectWrapper = ({ navigate, children }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (navigate !== null) {
      dispatch({ type: 'COMMON_NAVIGATE_TO', value: null });
    }
  }, [navigate]);

  if (navigate !== null) {
    return <Navigate to={navigate} />;
  }

  return children;
};

const mapStateToProps = ({ common }) => {
  const { navigate } = common;
  return {
    navigate,
  };
};

export default connect(mapStateToProps)(RedirectWrapper);
