import React, { useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableFooter from '@mui/material/TableFooter';
import Tooltip from '@mui/material/Tooltip';

import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

import BatchRow from '../BatchRow/BatchRow';
import Volume from '../Volume/Volume';

import batchReducer from '../../reducers/batchReducer';

import { getAllCountriesOfOriginAsync } from '../../reducers/thunks/countryOfOrigin.thunks';

import { usePrevious } from '../hooks';

function BatchTable(props) {
  const {
    batches,
    updateBatch,
    updateSubBatch,
    addSubBatch,
    removeSubBatch,
    addAttachments,
    useGallons
  } = props;
  console.log('batches', batches);
  const totalVolumeMemo = useMemo(() => {
    const newTotalVolume = batches.reduce((prev, current) => {
      return (prev += current.volume_cubic_meter_15c);
    }, 0);

    return newTotalVolume;
  }, [batches]);

  const totalDensityMemo = useMemo(() => {
    const newTotalDensity = batches.reduce((prev, current) => {
      return (prev +=
        (current.volume_cubic_meter_15c * current.density_kg_m3_15c) /
        totalVolumeMemo);
    }, 0);

    return newTotalDensity;
  }, [totalVolumeMemo, batches]);

  const prevTotalVolume = usePrevious(totalVolumeMemo);
  const prevTotalDensity = usePrevious(totalDensityMemo);
  useEffect(() => {
    if (prevTotalVolume !== undefined && prevTotalVolume !== totalVolumeMemo) {
      updateBatch({
        volume_cubic_meter_15c: totalVolumeMemo,
        volume_original_scale: useGallons ? 'gal' : 'm3_15c'
      });
    }
  }, [totalVolumeMemo, prevTotalVolume, useGallons, updateBatch]);

  useEffect(() => {
    if (prevTotalDensity !== totalDensityMemo) {
      updateBatch({
        density_kg_m3_15c: totalDensityMemo
      });
    }
  }, [totalDensityMemo, prevTotalDensity, updateBatch]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCountriesOfOriginAsync())
  }, [batchReducer]);

  return (
    <Grid container spacing={1} columns={24}>
      <Grid item xs={1}>
        <IconButton onClick={addSubBatch} aria-label='add batch'>
          <AddBoxRoundedIcon color={batches.length > 0 ? 'primary' : 'error'} />
        </IconButton>
      </Grid>
      <Grid item xs={22}>
        <TableContainer className='batchTable' component={Paper}>
          <Table sx={{ minWidth: 650 }} size='small'>
            <TableHead>
              <TableRow className='column'>
                <TableCell>Documents</TableCell>
                <TableCell>Source Location</TableCell>
                <TableCell>Source Tank</TableCell>
                <TableCell>Country of origin</TableCell>
                <TableCell>Vessel</TableCell>
                <Tooltip
                  title='You can change your measurement preferences from user menu.'
                  arrow
                  placement='top'
                >
                  <TableCell>
                    Volume{' '}
                    {useGallons ? (
                      'gallons'
                    ) : (
                      <React.Fragment>
                        m<sup>3</sup> @ 15&#8451;
                      </React.Fragment>
                    )}
                  </TableCell>
                </Tooltip>
                <TableCell>Load Batch No</TableCell>
                <TableCell>
                  Density <sup>kg</sup>/
                  <sub>
                    m<sup>3</sup>
                  </sub>{' '}
                  @ 15&#8451;
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody data-testid='subbatch-table-body'>
              {batches.map((row, index) => {
                return (
                  <BatchRow
                    key={`batch_row-${row.client_id}`}
                    batchReducer={batchReducer}
                    updateBatch={(updatedRow) =>
                      updateSubBatch(updatedRow, index)
                    }
                    removeBatch={removeSubBatch}
                    data={row}
                    index={index}
                    addAttachments={addAttachments}
                    isOriginbatch={row.is_origin_batch}
                  />
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className='batchCompText' colSpan={2}>
                  Current Batch Composition
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  Total volume <Volume volM3={totalVolumeMemo} />
                </TableCell>
                <TableCell />
                <TableCell className='batchCompText'>
                  Density {totalDensityMemo.toFixed(4)}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  const { common, preferences } = state;
  return { isLoading: common.isLoading, useGallons: preferences.gallons };
}

BatchTable.propTypes = {
  isLoading: PropTypes.bool,
  batches: PropTypes.arrayOf(PropTypes.object),
  updateBatch: PropTypes.func,
  updateComponentBatch: PropTypes.func,
  addAttachments: PropTypes.func,
  useGallons: PropTypes.bool.isRequired
};

export const areSubBatchesEqual = (a, b) => {
  let areEqual = true;
  areEqual = areEqual && a.batch_number === b.batch_number;
  areEqual = areEqual && a.coa_doc_link === b.coa_doc_link;
  areEqual = areEqual && a.composition_doc_link === b.composition_doc_link;
  areEqual = areEqual && a.radiocarbon_doc_link === b.radiocarbon_doc_link;
  areEqual = areEqual && a.calcblend_doc_link === b.calcblend_doc_link;
  areEqual = areEqual && a.rc_doc_link === b.rc_doc_link;
  areEqual = areEqual && a.coq_doc_link === b.coq_doc_link;
  areEqual = areEqual && a.coo_doc_link === b.coo_doc_link;
  areEqual = areEqual && a.tank_release_doc_link === b.tank_release_doc_link;
  areEqual = areEqual && a.density_kg_m3_15c === b.density_kg_m3_15c;
  areEqual = areEqual && a.tank_id === b.tank_id;
  areEqual = areEqual && a.volume_cubic_meter_15c === b.volume_cubic_meter_15c;
  areEqual = areEqual && a.volume_original_scale === b.volume_original_scale;
  areEqual = areEqual && a.is_component_batch === b.is_component_batch;
  areEqual = areEqual && a.id === b.id;
  areEqual = areEqual && a.vessel_name === b.vessel_name;
  // For origin batches also compare country of origin values
  if (!a.is_component_batch && !b.is_component_batch) {
    areEqual = areEqual && a.country_of_origin_id === b.country_of_origin_id;
  }

  return areEqual;
};

const arePropsEqual = (prevProps, currentProps) => {
  const batchesNumEqual =
    prevProps.batches.length === currentProps.batches.length;
  let batchesEqual = true;
  if (batchesNumEqual) {
    for (let i = 0; i < currentProps.batches.length; i++) {
      const a = prevProps.batches[i];
      const b = currentProps.batches[i];
      batchesEqual = batchesEqual && areSubBatchesEqual(a, b);
    }
  }

  const loadingEqual = prevProps.isLoading === currentProps.isLoading;

  const gallonsEqual = prevProps.useGallons === currentProps.useGallons;
  return batchesNumEqual && batchesEqual && loadingEqual && gallonsEqual;
};

export default connect(mapStateToProps)(memo(BatchTable, arePropsEqual));
// export default connect(mapStateToProps)(BatchTable);
