import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { connect } from 'react-redux';

function LocationSelect({ locations, isLoading, initialLocation, onChange, error }) {

  const [location, setLocation] = React.useState(initialLocation || '');

  const handleChange = (event) => {
    setLocation(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Box sx={{ minWidth: 230 }}>
      {isLoading ? (
        <div>Fetching...</div>
      ) : (
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="location-select">{'Location'}</InputLabel>
          <Select
            id="location-select"
            value={location}
            label="Location"
            onChange={handleChange}
            error={error}
            data-testid="location-select"
          >
            {
              // Only allow locations that have non-origin tanks available
              locations &&
                locations
                  .filter((loc) => loc.tanks && loc.tanks.some((tank) => tank.is_origin_tank === false))
                  .map((loc) => (
                    <MenuItem value={loc.location_name} key={loc.location_name}>
                      {loc.location_name}
                    </MenuItem>
                  ))
            }
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

function mapStateToProps(state) {
  const { common, batch } = state;
  return {
    isLoading: common.isLoading,
    locations: batch.locations,
  };
}

LocationSelect.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  initialLocation: PropTypes.string,
  onChange: PropTypes.func,
};

export default connect(mapStateToProps)(LocationSelect);
