export const initialState = {
  locations: [],
  tanks: [],
  products: [],
  locationsLoading: false,
  locationsError: false,
  locationCreateLoading: false,
  locationCreateError: false,
  locationEditLoading: false,
  tanksLoading: false,
  tanksError: false,
  tankCreateLoading: false,
  tankEditLoading: false,
  tankEditError: false,
  productsLoading: false,
  productsError: false,
  productCreateLoading: false,
  productCreateError: false,
  productEditLoading: false,
  productEditError: false,
  lastUpdate: Date.now(),
};

const mdmReducer = (state = initialState, { type, value }) => {
  switch (type) {
  case 'MDM_SET_LOCATIONS':
    return {
      ...state,
      locations: value,
    };
  case 'MDM_SET_LOCATIONS_LOADING':
    return {
      ...state,
      locationsLoading: value,
      locationsError: value === true ? false : state.locationsError,
    };
  case 'MDM_SET_LOCATIONS_ERROR':
    return {
      ...state,
      locationsError: true,
    };
  case 'MDM_ADD_LOCATION':
    return {
      ...state,
      locations: [
        ...state.locations,
        value,
      ],
      lastUpdate: Date.now(),
    };
  case 'MDM_SET_LOCATION_CREATE_LOADING':
    return {
      ...state,
      locationCreateLoading: value,
      locationCreateError: value === true ? false : state.locationCreateError,
    };
  case 'MDM_SET_LOCATION_CREATE_ERROR':
    return {
      ...state,
      locationCreateError: true,
    };
  case 'MDM_SET_LOCATION_EDIT_LOADING':
    return {
      ...state,
      locationEditLoading: value,
    };
  case 'MDM_UPDATE_LOCATION': {
    const newLocations = [ ...state.locations ];
    const found = newLocations.find((loc) => loc.id === value.id);
    const index = newLocations.indexOf(found);

    newLocations.splice(index, 1, value.location);
    return {
      ...state,
      locations: newLocations,
      lastUpdate: Date.now(),
    };
  }
  case 'MDM_SET_TANKS':
    return {
      ...state,
      tanks: value,
    };
  case 'MDM_SET_TANKS_LOADING':
    return {
      ...state,
      tanksLoading: value,
      tanksError: value === true ? false : state.tanksError,
    };
  case 'MDM_SET_TANKS_ERROR':
    return {
      ...state,
      tanksError: true,
    };
  case 'MDM_ADD_TANK':
    return {
      ...state,
      tanks: [
        ...state.tanks,
        value,
      ],
      lastUpdate: Date.now(),
    };
  case 'MDM_UPDATE_TANK': {
    const newTanks = [ ...state.tanks ];
    const found = newTanks.find((tank) => tank.id === value.id);
    const index = newTanks.indexOf(found);

    newTanks.splice(index, 1, value.tank);
    return {
      ...state,
      tanks: newTanks,
      lastUpdate: Date.now(),
    };
  }
  case 'MDM_SET_TANK_CREATE_LOADING':
    return {
      ...state,
      tankCreateLoading: value,
    };
  case 'MDM_SET_TANK_EDIT_LOADING':
    return {
      ...state,
      tankEditLoading: value,
      tankEditError: value === true ? false : state.tankEditError,
    };
  case 'MDM_SET_TANK_EDIT_ERROR':
    return {
      ...state,
      tankEditError: value === false ? false : true,
    };
  case 'MDM_SET_PRODUCTS':
    return {
      ...state,
      products: value,
    };
  case 'MDM_SET_PRODUCTS_LOADING':
    return {
      ...state,
      productsLoading: value,
      productsError: value === true ? false : state.productsError,
    };
  case 'MDM_SET_PRODUCTS_ERROR':
    return {
      ...state,
      productsError: true,
    };
  case 'MDM_ADD_PRODUCT':
    return {
      ...state,
      products: [
        ...state.products,
        value,
      ],
      lastUpdate: Date.now(),
    };
  case 'MDM_SET_PRODUCT_CREATE_LOADING':
    return {
      ...state,
      productCreateLoading: value,
      productCreateError: value === true ? false : state.productCreateError,
    };
  case 'MDM_SET_PRODUCT_CREATE_ERROR':
    return {
      ...state,
      productCreateError: true,
    };
  case 'MDM_UPDATE_PRODUCT': {
    const newProducts = [ ...state.products ];
    const found = newProducts.find((product) => product.id === value.product.id);
    const index = newProducts.indexOf(found);

    newProducts.splice(index, 1, value.product);
    return {
      ...state,
      products: newProducts,
      lastUpdate: Date.now(),
    };
  }
  case 'MDM_SET_PRODUCT_EDIT_LOADING':
    return {
      ...state,
      productEditLoading: value,
      productEditError: value === true ? false : state.productEditError,
    };
  case 'MDM_SET_PRODUCT_EDIT_ERROR':
    return {
      ...state,
      productEditError: value === false ? false : true,
    };
  default:
    return state;
  };
};

export default mdmReducer;
