import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';

import userReducer from './reducers/userReducer';
import commonReducer from './reducers/commonReducer';
import batchReducer from './reducers/batchReducer';
import createEditReducer from './reducers/createEditReducer';
import auditReducer from './reducers/auditReducer';
import mdmReducer from './reducers/mdmReducer';
import userPreferencesReducer from './reducers/userPreferencesReducer';

export const rootReducer = combineReducers({
  user: userReducer,
  common: commonReducer,
  batch: batchReducer,
  edit: createEditReducer,
  audit: auditReducer,
  mdm: mdmReducer,
  preferences: userPreferencesReducer
});

const createPersistedStore = (storage) => {
  const persistConfig = {
    key: 'jig-root',
    storage,
    whitelist: ['preferences']
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );

  return store;
};

export default createPersistedStore;

export const createPersistor = (store) => {
  return persistStore(store);
};
