import React, { useState, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import ApprovedIcon from '../../static/approved.svg';

import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import { approveBatchAsync } from '../../reducers/thunks/batches.thunks';
import { generateReportAsync } from '../../reducers/thunks/attachments.thunks';
import { generateOriginReportAsync } from '../../reducers/thunks/attachments.thunks';
import { useOpenReportLink } from '../hooks';
import DatePicker from '../DatePickerComponent/DatePicker';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function BatchDetails(props) {
  const dispatch = useDispatch();
  const { batchReducer, user, isReportLoading, isOriginReportLoading } = props;

  const { batchDetails: batch } = batchReducer;

  const { batch_number: batchNumber, id: batchId } = batch;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [threshold, setThreshold] = useState(0);
  const [date, setDate] = React.useState(null);

  const validThreshold = useMemo(() => {
    if (!threshold) {
      return false;
    }
    const num = parseFloat(threshold.replace(',', '.'));
    if (num < 0 || num > 100) {
      return false;
    }

    return true;
  }, [threshold]);

  const [approveMessage, setApproveMessage] = useState('');

  const navigate = useNavigate();

  const goToAudit = () => {
    navigate(`/audit/batch?s=${encodeURIComponent(batchNumber)}`);
  };

  const approveBatch = (batchId, message) => {
    dispatch(approveBatchAsync(batchId, message));

    //TODO retrieval of actual latest data instead of below hack?
    batch.approval_remarks = message;
    batch.approved_at = new Date().toISOString();
    batch.approved_by = user.email;
  };

  const generateReport = (batchId, threshold, date) => {
    dispatch(generateReportAsync(batchId, threshold, date));
  };

  const generateCountryOfOriginReport = (batchId) => {
    dispatch(generateOriginReportAsync(batchId));
  };

  const editBatch = (batchId) => {
    navigate(`/batch-view/${batchId}/edit`);
  };

  useOpenReportLink(batchReducer.reportlink, dispatch, 'SET_REPORT_LINK');
  useOpenReportLink(batchReducer.originReportLink, dispatch, 'SET_ORIGIN_REPORT_LINK');

  const isAdmin = 'admin' === user.role;
  const { t } = useTranslation();

  const renderModal = () => {
    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack alignItems="center" spacing={2} direction="column">
            <img src={ApprovedIcon} alt={''} />
            <Typography variant="h6" gutterBottom component="div">
              Approve batch
            </Typography>
            <Typography variant="h5" gutterBottom component="div">
              Batch no: {batchNumber}
            </Typography>
            <TextField
              id="batch-approve-message"
              label="Approve message"
              size="small"
              autoFocus
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              onChange={(event) => setApproveMessage(event.target.value)}
            ></TextField>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                data-testid="batch-approve"
                onClick={() => {
                  setOpen(false);
                  approveBatch(batchId, approveMessage);
                }}
              >
                Approve
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    );
  };

  if (!batch) {
    return null;
  }

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          marginBottom: {
            xs: 2,
            lg: -14,
          },
        }}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="h6" component="h6" color="text.primary">
          Batch Calculation of Jet Fuel
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box>
            {isAdmin ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleOpen}
                endIcon={<CheckIcon />}
              >
                {t('batch_approve', 'Approve')}
              </Button>
            ) : null}
          </Box>
          <Stack alignItems="left" spacing={2} direction="column">
            <Button
              color="primary"
              variant="contained"
              onClick={() => editBatch(batchId)}
              endIcon={<EditIcon />}
            >
              {t('batch_edit', 'Edit')}
            </Button>
          </Stack>
          <Box>
            <Button
              color="primary"
              variant="contained"
              endIcon={<ReceiptLongIcon />}
              onClick={() => goToAudit()}
            >
              Audit
            </Button>
          </Box>
          <Stack alignItems="left" spacing={2} direction="column">
            <LoadingButton
              color="primary"
              variant="contained"
              endIcon={<InsertDriveFileIcon />}
              loadingPosition="end"
              loading={isOriginReportLoading}
              onClick={() => {
                setOpen(false);
                generateCountryOfOriginReport(batchId);
              }}
            >
              Generate origin report
            </LoadingButton>
            <LoadingButton
              color="primary"
              variant="contained"
              endIcon={<InsertDriveFileIcon />}
              loadingPosition="end"
              loading={isReportLoading}
              disabled={!validThreshold}
              onClick={() => {
                setOpen(false);
                generateReport(batchId, threshold, date);
              }}
            >
              {t('batch_report', 'Generate report')}
            </LoadingButton>
            <TextField
              id="report-threshold"
              size="small"
              variant="outlined"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                  max: 100,
                  'data-testid': 'report-threshold',
                },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(event) => setThreshold(event.target.value)}
            ></TextField>
            <DatePicker
              id="start-date"
              label="Start date"
              size="small"
              variant="outlined"
              value={date}
              onChange={setDate}
            ></DatePicker>
          </Stack>
        </Stack>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={9}>
          <TableContainer component={Paper}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell align="left">Tank</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Batch Number</TableCell>
                  <TableCell align="left">Report Number</TableCell>
                  <TableCell align="left">Approver Name</TableCell>
                  <TableCell align="left">Approval Date</TableCell>
                  <TableCell align="left">Approval Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={batch.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left">{batch.location_name}</TableCell>
                  <TableCell align="left">{batch.tank_name}</TableCell>
                  <TableCell align="left">
                    {new Date(batch.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="left">{batch.batch_number}</TableCell>
                  <TableCell align="left">{batch.report_number}</TableCell>
                  <TableCell align="left">
                    <Box sx={{ maxWidth: 300 }}>
                      <Tooltip title={batch.approved_by || ''}>
                        <Typography noWrap={true}>
                          {batch.approved_by}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {batch.approved_at == null
                      ? null
                      : new Date(batch.approved_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="left">{batch.approval_remarks}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {renderModal()}
    </Box>
  );
}

function mapStateToProps(state) {
  const { user, common } = state;
  return {
    user: user.currentUser,
    isReportLoading: common.isReportLoading,
    isOriginReportLoading: common.isOriginReportLoading,
  };
}

export default connect(mapStateToProps)(BatchDetails);
