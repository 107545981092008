import React from 'react';
import PropTypes from 'prop-types';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import LabelIcon from '@mui/icons-material/Label';

const BatchTreeList = ({ items, indentLevel, subheader }) => {

  if (items) {
    return (
      <List disablePadding subheader={subheader ? <ListSubheader>{subheader}</ListSubheader> : null}>
        {
          items.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem sx={{ pl: indentLevel * 4 }}>
                <ListItemIcon sx={{ mr: -3 }}>
                  <LabelIcon htmlColor="#c3c8c8" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={item.batch_number}/>
              </ListItem>
              {
                item.parent_batches && (
                  <BatchTreeList items={item.parent_batches} indentLevel={indentLevel + 1}/>
                )
              }
            </React.Fragment>
          ))
        }
      </List>
    );
  } else {
    return (
      <div>
        <p>No information with selected date</p>
      </div>
    );
  }
};

BatchTreeList.propTypes = {
  indentLevel: PropTypes.number,
  subheader: PropTypes.string,
};

BatchTreeList.defaultProps = {
  indentLevel: 0,
};

export default BatchTreeList;