/* eslint-disable default-param-last */
const initialState = {
  usersLoading: false,
  usersError: false,
  userCreateLoading: false,
  userCreateError: false,
  userEditLoading: false,
  userDeleteError: false,
  userDeleteLoading: false,
  currentUser: null,
  users: [],
};

const userReducer = (state = initialState, { type, value }) => {
  switch (type) {
  case 'SET_USER':
    return { 
      ...state, 
      currentUser: value, 
    };
  case 'SET_USERLIST':
    return { 
      ...state, 
      users: value, 
    };
  case 'USER_LOG_OUT':
    return initialState;
  case 'SET_USERS_LOADING':
    return { 
      ...state, 
      usersLoading: value, 
      usersError: value === true ? false : state.usersError,
    };
  case 'SET_USERS_ERROR':
    return {
      ...state,
      productsError: true,
    };
  case 'ADD_USER':
    return {
      ...state,
      users: [
        ...state.users,
        value,
      ],
    };
  case 'USER_ADD': {
    const newUsers = [...state.users];
    newUsers.unshift({
      ...value,
      id: value.email,
    });
    return {
      ...state,
      users: newUsers,
    };
  }
  case 'USER_DELETE': {
    const newUsers = [...state.users];
    const found = newUsers.find((user) => user.email === value);
    const index = newUsers.indexOf(found);

    newUsers.splice(index, 1);
    return {
      ...state,
      users: newUsers,
    };
  }
  case 'USER_DELETE_LOADING': {
    return {
      ...state,
      userDeleteLoading: value,
      userDeleteError: value === true ? false : state.userDeleteError,
    };
  }
  case 'USER_DELETE_ERROR': {
    return {
      ...state,
      userDeleteError: value,
    };
  }
  case 'SET_USER_CREATE_LOADING':
    return {
      ...state,
      userCreateLoading: value,
      userCreateError: value === true ? false : state.userCreateError,
    };
  case 'SET_USER_CREATE_ERROR':
    return {
      ...state,
      userCreateError: true,
    };
  case 'UPDATE_USER': {
    const newUsers = [...state.users];
    const found = newUsers.find((user) => user.email === value.user.email);
    const index = newUsers.indexOf(found);

    value.user.id = value.user.email;
    newUsers.splice(index, 1, value.user);
    return {
      ...state,
      users: newUsers,
    };
  }
  case 'SET_USER_EDIT_LOADING':
    return {
      ...state,
      userEditLoading: value,
    };
  default:
    return state;
  }
};

export default userReducer;
