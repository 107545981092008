import React from 'react';
import { useDispatch, connect } from 'react-redux';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import BatchTreeList from '../BatchTree/BatchTreeList';
import DatePicker from '../DatePickerComponent/DatePicker';

import { getBatchHierarchyAsync } from '../../reducers/thunks/batches.thunks';

function SubBatchDetails({
  batchTree,
  batchTreeLoading,
  batchId,
}) {
  const dispatch = useDispatch();
  const [date, setDate] = React.useState('');
  
  const getBatchTree = () => {
    dispatch(getBatchHierarchyAsync(batchId, date));
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="flex-start" spacing={2}>
        <LoadingButton variant="contained" onClick={getBatchTree} loading={batchTreeLoading}>
            Show Batch Hierarchy Tree
        </LoadingButton>
        <DatePicker onChange={setDate} />
      </Stack>
      {batchTree !== null ? (
        <Stack direction="row" justifyContent="flex-start" sx={{ pt: 2 }}>
          <Paper sx={{pt: 1.5, pb: 1.5, pl: 2, pr: 2, minWidth: 525 }} elevation={4}>
            <Typography variant="h6">{batchTree.batch_number}</Typography>
            <BatchTreeList items={batchTree.parent_batches}/>
          </Paper>
        </Stack>
      ) : (
        null
      )}
    </Box>
  );
}

function mapStateToProps({ batch }) {
  const {
    batchDetails,
    batchTree,
    batchTreeLoading,
  } = batch;
  return {
    batchId: batchDetails.id,
    batchTree,
    batchTreeLoading,
  };
}

export default connect(mapStateToProps)(SubBatchDetails);
