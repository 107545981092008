/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import GoogleAuth from './components/GoogleAuth/GoogleAuth';

function AuthRoute(props) {
  const { user, child } = props;
  return user ? child : <GoogleAuth />;
}

function mapStateToProps(state) {
  const { user } = state;
  return { user: user.currentUser };
}

export default connect(mapStateToProps)(AuthRoute);
