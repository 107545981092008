import { useRef, useEffect } from 'react';

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// opening a report link and clear the link after
export const useOpenReportLink = (reportLink, dispatch, actionType) => {
  const prevReportLink = usePrevious(reportLink);

  useEffect(() => {
    if (reportLink && !prevReportLink) {
      window.open(reportLink.report_link);
      // Dispatch an action to clear the report link in the Redux store
      dispatch({ type: actionType, value: null });
    }
  }, [reportLink, dispatch, prevReportLink, actionType]);
};
