import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import firebase, { signInWithGoogle } from '../../static/firebase';
import GoogleLogo from '../../static/google-logo.svg';
import './GoogleAuth.css';

function GoogleAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // Set default loading to true
  const [isLoading, setIsLoading] = useState(true);
  // If user is already logged on, navigate to home screen

  const getClaims = async() => {
    return firebase.auth().currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        return idTokenResult.claims;
      });
  };

  const setUser = async(user) => {
    const claims = await getClaims();
    const role = claims.roles ? claims.roles[0] : null;
    
    dispatch({
      type: 'SET_USER',
      value: { uid: user.uid, displayName: user.displayName, email: user.email, role: role },
    });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        navigate(`${window.location.pathname}${window.location.search}`);
      }
      // if firebase has established connection, set loading to false
      // This will stuck on loading if no connection is available, TODO
      setIsLoading(false);
    });
  }, [dispatch, navigate]);

  return (
    <div className="jig_landing-page">
      <div className="google-auth-container">
        {isLoading ? (
          <Box className="jig_login-loading">
            <CircularProgress size={50} />
          </Box>
        ) : (
          <>
            <h2>{t('jig_title', 'JIG Traceability')}</h2>
            <div>Please login to continue</div>
            <img
              src={GoogleLogo}
              width={20}
              height={20}
              id="jig_google-logo"
              alt=""
            />
            <Button
              className="google-auth-button"
              onClick={() => {
                setIsLoading(true);
                signInWithGoogle();
                navigate('/batch-search');
              }}
              size="small"
              variant="contained"
            >
              {t('google.sign-in')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default GoogleAuth;
