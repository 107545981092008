import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

function TankShipSelect({
  onChange,
  locationName,
  locations,
  initialValue,
  disabled,
  error,
  value,
}) {
  const [tankship, setTankShip] = React.useState(initialValue || '');
  const currentLocation = locations.find(l => l.location_name === locationName);
  const handleChange = (event) => {
    setTankShip(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Box sx={{ minWidth: 230 }}>
      <Tooltip arrow placement="top" title={disabled ? 'Select a location first' : ''}>
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="tankship-select">Tank/Ship</InputLabel>
          <Select
            id="tankship-select"
            value={value !== undefined ? value : tankship}
            label="Tank/Ship"
            onChange={handleChange}
            disabled={disabled}
            error={error}
            data-testid="tankship-select"
          >
            {currentLocation &&
              currentLocation?.tanks?.map((tank) => {
                return <MenuItem value={tank.id} key={tank.id}>{tank.tank_name}</MenuItem>;
              })}
          </Select>
        </FormControl>
      </Tooltip>
    </Box>
  );
}

TankShipSelect.propTypes = {
  locationName: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

TankShipSelect.defaultProps = {
  disabled: false,
  error: false,
};

function mapStateToProps(state) {
  const { batch } = state;
  return {
    locations: batch.locations,
  };
}

export default connect(mapStateToProps)(TankShipSelect);
