import axios from 'axios';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

// Treat the api client as a singleton, no need to create a new one for each request:
// Firebase auth will be called on each request anyway to refresh the token if needed.
let instance = null;

const apiClient = () => {
  if (instance) {
    return instance;
  }

  const REACT_APP_API_URL = `${process.env.REACT_APP_API_BASE_URL}`;
  
  const axiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
  });
  axiosInstance.interceptors.request.use(async function (config) {
    const token = await refreshToken();
    config.headers['Authorization'] = token;
    return config;
  });

  instance = axiosInstance;
  return instance;
};

const refreshToken = () => {
  const newIdToken = firebase
    .auth()
    .currentUser.getIdToken()
    .then((result) => {
      return result;
    });
  return newIdToken;
};

export default apiClient;
