import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import DeleteIcon from '@mui/icons-material/Delete';

function UploadDocuments({ title, type, batch, submit, error, showRemove, removeAttachment, allowUpload }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setOpen] = useState(false);
  const [selectedCOAFile, setSelectedCOAFile] = useState(null);
  const [selectedC14File, setSelectedC14File] = useState(null);
  const [selectedBatchCompFile, setSelectedBatchCompFile] = useState(null);
  const [selectedStatementOfCalcBlendFile, setSelectedStatementOfCalcBlendFile] = useState(null);
  const [selectedRCFile, setSelectedRCFile] = useState(null);
  const [selectedCoQFile, setSelectedCoQFile] = useState(null);
  const [selectedCOOFile, setSelectedCOOFile] = useState(null);
  const [selectedTankReleaseFile, setSelectedTankReleaseFile] = useState(null);

  const changeHandler = (file, doc) => {
    switch (doc) {
    case 'COA':
      setSelectedCOAFile(file);
      break;
    case 'C14':
      setSelectedC14File(file);
      break;
    case 'Batch Composition':
      setSelectedBatchCompFile(file);
      break;
    case 'Statement of calc.blend%':
      setSelectedStatementOfCalcBlendFile(file);
      break;
    case 'Recertification Test':
      setSelectedRCFile(file);
      break;
    case 'COQ':
      setSelectedCoQFile(file);
      break;
    case 'COO':
      setSelectedCOOFile(file);
      break;
    case 'Tank Release':
      console.log(`${file}`)
      setSelectedTankReleaseFile(file);
      break;
    default:
      return null;
    }
  };

  const cancelUpload = () => {
    setSelectedBatchCompFile(null);
    setSelectedC14File(null);
    setSelectedCOAFile(null);
    setSelectedStatementOfCalcBlendFile(null);
    setSelectedRCFile(null);
    setSelectedCoQFile(null);
    setSelectedCOOFile(null);
    setSelectedTankReleaseFile(null);
    setDrawerOpen(false);

    setOpen(false);
  };

  const handleSubmission = () => {

    const files = {
      composition: selectedBatchCompFile,
      coa: selectedCOAFile,
      radiocarbon: selectedC14File,
      calcblend: selectedStatementOfCalcBlendFile,
      rc: selectedRCFile,
      coq: selectedCoQFile,
      coo: selectedCOOFile,
      tankrelease: selectedTankReleaseFile
    };

    submit(files);
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(value);
  };

  const renderDialog = () => {
    if (selectedCOAFile || selectedC14File || selectedBatchCompFile|| selectedStatementOfCalcBlendFile || selectedRCFile || selectedCoQFile || selectedCOOFile || selectedTankReleaseFile)
      return (
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Document Upload'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {type
                ? `Attach uploaded documents to ${type}?`
                : 'Attach uploaded documents?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelUpload}>Cancel</Button>
            <Button
              onClick={() => {
                setOpen(false);
                setDrawerOpen(false);
                handleSubmission();
              }}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      );
  };

  const list = () => (
    <Box sx={{ width: 250 }} onKeyDown={toggleDrawer(false)}>
      <List variant="contained">
        {['COA', 'C14', 'Batch Composition', 'Statement of calc.blend%', 'Recertification Test', 'COQ', 'COO', 'Tank Release'].map((doc) => (
          <div style={{ margin: 10 }} key={doc}>
            {doc}
            <input
              style={{ marginTop: 5 }}
              onClick={(e) => {
                e.target.value = null;
                changeHandler(null, doc);
              }}
              type="file"
              name="file"
              data-testid={`${doc.replace(' ', '-')}-input`}
              onChange={(e) => changeHandler(e.target.files[0], doc)}
            />
          </div>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Stack direction="row" justifyContent="flex-start" spacing={2} alignItems="center">
        {
          allowUpload ?
            <Button
              onClick={toggleDrawer(true)}
              startIcon={<AttachFileRoundedIcon />}
              sx={{ color: error ? 'red' : undefined }}
            >
              {title ? title : 'Batch Documents'}
            </Button> : <Box sx={{ minWidth: '120px'}} />
        }
        {
          batch.coa_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove COA document from batch">
                    <IconButton onClick={() => removeAttachment('COA')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.coa_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                  COA
                </Typography>
              </Tooltip>
            </Stack>
        }
        {
          batch.radiocarbon_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove C14 document from batch">
                    <IconButton onClick={() => removeAttachment('C14')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.radiocarbon_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                C14
                </Typography>
              </Tooltip>
            </Stack>
        }
        {
          batch.composition_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove Batch composition document from batch">
                    <IconButton onClick={() => removeAttachment('COMP')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.composition_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                Batch composition
                </Typography>
              </Tooltip>
            </Stack>
        }
        {
          batch.calcblend_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove Statement of calc.blend% document from batch">
                    <IconButton onClick={() => removeAttachment('CALCBLEND')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.calcblend_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                  Statement of calc.blend%
                </Typography>
              </Tooltip>
            </Stack>
        }
        {
          batch.rc_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove Statement of Recertification Test document from batch">
                    <IconButton onClick={() => removeAttachment('RC')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.rc_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                  Recertification Test
                </Typography>
              </Tooltip>
            </Stack>
        }
        {
          batch.coq_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove Statement of Quality document from batch">
                    <IconButton onClick={() => removeAttachment('COQ')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.coq_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                  COQ
                </Typography>
              </Tooltip>
            </Stack>
        }
        {
          batch.coo_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove COO document from batch">
                    <IconButton onClick={() => removeAttachment('COO')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.coo_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                  COO
                </Typography>
              </Tooltip>
            </Stack>
        }

        {
          batch.tank_release_doc_name &&
            <Stack direction="row" justifyContent="flex-start" spacing={0} alignItems="center">
              {
                showRemove &&
                  <Tooltip title="Remove Tank Release document from batch">
                    <IconButton onClick={() => removeAttachment('Tank_release')}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
              }
              <Tooltip title={batch.tank_release_doc_name || ''}>
                <Typography sx={{ fontSize: 12 }}>
                  Tank Release
                </Typography>
              </Tooltip>
            </Stack>
        }

      </Stack>
      <Drawer
        anchor="right"
        open={drawerOpen}
        ModalProps={{
          keepMounted: false,
        }}
        onClose={(ev, reason) => {
          if (reason === 'backdropClick') {
            if (selectedBatchCompFile || selectedC14File || selectedCOAFile) {
              setOpen(true);
            } else {
              setDrawerOpen(false);
            }
          }
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Stack direction="row-reverse" sx={{ width: '75%', overflowWrap: 'anywhere' }}>
            <h4>{type ? `Upload documents to ${type}` : 'Upload documents'}</h4>
            <IconButton onClick={toggleDrawer(!drawerOpen)}>
              {drawerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Stack>
          {list()}
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={() => {
                setDrawerOpen(false);
                handleSubmission();
              }}
              data-testid="document-save"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                cancelUpload();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Drawer>
      {renderDialog()}
    </Box>
  );
}

UploadDocuments.propTypes = {
  isDocumentsLoading: PropTypes.bool,
  batch: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  allowUpload: PropTypes.bool,
  showRemove: PropTypes.bool,
  removeAttachment: PropTypes.func,
};

UploadDocuments.defaultProps = {
  showRemove: false,
  allowUpload: true,
};

function mapStateToProps(state) {
  const { common } = state;
  return {
    isDocumentsLoading: common.isDocumentsLoading,
  };
}

export default connect(mapStateToProps)(UploadDocuments);
