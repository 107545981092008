import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import TankAndLocationsTable from '../TankAndLocationsTable/TankAndLocationsTable';
import ViewContainer from '../ViewContainer/ViewContainer';
import BatchTable, { areSubBatchesEqual } from '../BatchTable/BatchTable';
import BatchEditConfirmationModal from '../BatchEditConfirmationModal/BatchEditConfirmationModal';

import {
  getBatchAsync,
  getLocationsAsync,
  sendBatchAsync,
  getBatchChildrenAsync,
  updateBatchAsync
} from '../../reducers/thunks/batches.thunks';
import { addAttachmentsAsync } from '../../reducers/thunks/attachments.thunks';
function BatchManagement(props) {
  const {
    locations,
    loadedBatch,
    isLoading,
    fetchError,
    edit,
    editBatch,
    isChildrenLoading,
    childrenError,
    childBatches,
    // countriesOfOrigin
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // This is to hide the initial render, before the first effects have been triggered.
  // With it, we'll avoid lingering initial values in the fields from previously edited batches,
  // when moving to batch creation without clearing the store otherwise.
  const [renderSelf, setRenderSelf] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const isValid = useMemo(() => {
    // Required documents
    let status = !!editBatch.composition_doc_link || !!editBatch.coa_doc_link;
  
    // At least one component or origin batch
    status = status && editBatch.subBatches.length > 0;
  
    // Main batch number and tank are given
    status = status && !!editBatch.batch_number && editBatch.tank_id >= 0;
  
    // Date is given
    status = status && !!editBatch.date;
  
    // All sub batches have a tank id
    // component batches have batch id
    // origin batches have a batch number AND country_of_origin_id
    editBatch.subBatches.forEach((sb) => {
      status =
        status &&
        sb.tank_id >= 0 &&
        ((sb.is_component_batch && sb.id) ||
          (sb.is_origin_batch && sb.batch_number && sb.country_of_origin_id >= 0));

      // Volume is valid
      status =
        status &&
        !isNaN(sb.volume_cubic_meter_15c) &&
        sb.volume_cubic_meter_15c > 0;

      // If origin batch, density is valid
      status =
        status &&
        (sb.is_component_batch ||
          (sb.density_kg_m3_15c > 0 && !isNaN(sb.density_kg_m3_15c)));
    });

    return status;
  }, [
    editBatch.composition_doc_link,
    editBatch.coa_doc_link,
    editBatch.subBatches,
    editBatch.batch_number,
    editBatch.tank_id,
    editBatch.date
  ]);
  
  useEffect(() => {
    // If we're editing an existing batch, the data is loaded in to
    // the batch reducer, and then here we copy it to the createEditReducer
    // to be used in this view.
    if (edit) {
      if (loadedBatch) {
        const batchToEdit = {
          ...loadedBatch,
          composition_doc_name: loadedBatch.composition_doc_link,
          coa_doc_name: loadedBatch.coa_doc_link,
          radiocarbon_doc_name: loadedBatch.radiocarbon_doc_link,
          calcblend_doc_name: loadedBatch.calcblend_doc_link,
          rc_doc_name: loadedBatch.rc_doc_link,
          coq_doc_name: loadedBatch.coq_doc_link,
          coo_doc_name: loadedBatch.coo_doc_link,
          tank_release_doc_name: loadedBatch.tank_release_doc_link
        };

        dispatch({
          type: 'EDIT_SET_DATA',
          value: batchToEdit
        });
      }
    } else {
      dispatch({
        type: 'EDIT_CLEAR'
      });
    }
    setRenderSelf(true);
  }, [dispatch, edit, loadedBatch]);

  useEffect(() => {
    if (!locations || locations.length < 1) {
      dispatch(getLocationsAsync());
    }
  }, [dispatch, locations]);

  const { id } = useParams();
  useEffect(() => {
    if (edit) {
      dispatch(getBatchAsync(id, false));
    } else {
      dispatch({
        type: 'CLEAR_BATCH_DETAILS'
      });
    }
  }, [dispatch, id, edit]);

  const updateBatch = useCallback(
    (partialBatch) => {
      dispatch({
        type: 'EDIT_UPDATE_DATA',
        value: partialBatch
      });
    },
    [dispatch]
  );

  const updateSubBatch = useCallback(
    (batch, index) => {
      console.log(`updateSubBatch(${batch}, ${index})`);
      dispatch({
        type: 'EDIT_UPDATE_SUB_BATCH',
        value: {
          batch,
          index
        }
      });
    },
    [dispatch]
  );

  const addSubBatch = useCallback(() => {
    dispatch({
      type: 'EDIT_ADD_SUB_BATCH'
    });
  }, [dispatch]);

  const removeSubBatch = useCallback(
    (index) => {
      dispatch({
        type: 'EDIT_REMOVE_SUB_BATCH',
        value: index
      });
    },
    [dispatch]
  );

  const doAddAttachments = useCallback(
    (files, isComponent, index) => {
      dispatch(addAttachmentsAsync(files, isComponent, index));
    },
    [dispatch]
  );

  const doUpdateBatch = useCallback(() => {
    dispatch(updateBatchAsync());
    closeModal();
  }, [dispatch]);

  const doCreateBatch = useCallback(() => {
    if (edit) {
      if (editBatch.isDirty) {
        dispatch(getBatchChildrenAsync(editBatch.id));
        setShowConfirmationModal(true);
      } else {
        doUpdateBatch();
      }
    } else {
      dispatch(sendBatchAsync());
    }
  }, [dispatch, editBatch.isDirty, edit, editBatch.id, doUpdateBatch]);

  const cancelUpdateCreateBatch = useCallback(() => {
    dispatch({ type: 'CLEAR_BATCH_DATA' });
    dispatch({ type: 'EDIT_CLEAR' });
    navigate('/batch-search');
  }, [dispatch, navigate]);

  const closeModal = () => {
    setShowConfirmationModal(false);
  };

  if (!renderSelf) {
    return null;
  }

  return (
    <ViewContainer
      title={
        edit
          ? `Edit batch${isLoading ? '' : ` "${editBatch.batch_number}"`}`
          : 'Create new batch'
      }
    >
      <React.Fragment>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='h6' component='h6' color='text.primary'>
            Batch Calculation of Jet Fuel
          </Typography>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
          >
            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={doCreateBatch}
              disabled={!isValid}
            >
              Save
            </Button>
            <Button
              variant='contained'
              color='secondary'
              size='small'
              onClick={cancelUpdateCreateBatch}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ marginTop: 2 }}>
          {isLoading ? (
            <Box className='jig_login-loading'>
              <CircularProgress size={40} />
            </Box>
          ) : fetchError ? (
            <h3>Something went wrong with the request</h3>
          ) : (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TankAndLocationsTable
                    locations={locations}
                    batch={editBatch}
                    updateBatch={updateBatch}
                    addAttachments={doAddAttachments}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BatchTable
                    batches={editBatch.subBatches}
                    updateSubBatch={updateSubBatch}
                    updateBatch={updateBatch}
                    addSubBatch={addSubBatch}
                    removeSubBatch={removeSubBatch}
                    doCreateBatch={doCreateBatch}
                    addAttachments={doAddAttachments}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Box>
        <BatchEditConfirmationModal
          show={showConfirmationModal}
          loading={isChildrenLoading}
          error={childrenError}
          close={closeModal}
          confirm={doUpdateBatch}
          batches={childBatches}
        />
      </React.Fragment>
    </ViewContainer>
  );
}

function mapStateToProps(state) {
  const { batch, common, edit } = state;
  return {
    countriesOfOrigin: batch.countriesOfOrigin,
    locations: batch.locations,
    loadedBatch: batch.batchDetails,
    isLoading:
      common.isLoading ||
      common.isBatchLoading ||
      batch.locationsLoading ||
      batch.originBatchLoading ||
      batch.countriesOfOriginLoading,
    fetchError: common.fetchError,
    editBatch: edit.batch,
    isChildrenLoading: edit.childrenLoading,
    childrenError: edit.childrenError,
    childBatches: edit.children
  };
}

BatchManagement.propTypes = {
  isChildrenLoading: PropTypes.bool,
  childrenError: PropTypes.bool,
  isLoading: PropTypes.bool,
  fetchError: PropTypes.bool,
  edit: PropTypes.bool
};

BatchManagement.defaultProps = {
  edit: false
};

const arePropsEqual = (p, c) => {
  const loadedBatchEqual = p.loadedBatch?.id === c.loadedBatch?.id;

  const editEqual = p.edit === c.edit;
  const childErrorEqual = p.childrenError === c.childrenError;
  const fetchErrorEqual = p.fetchError === c.fetchError;
  const childLoadingEqual = p.isChildrenLoading === c.isChildrenLoading;
  const loadingEqual = p.isLoading === c.isLoading;

  const locationsEqual = p.locations.length === c.locations.length;
  // In theory, don't need to check the children in much detail as they are used in the confirmation modal,
  // and always accompanied with the childrenLoading status changes.
  const childrenEqual = p.childBatches.length === c.childBatches.length;

  let editBatchEqual = true;
  editBatchEqual =
    editBatchEqual && p.editBatch.batch_number === c.editBatch.batch_number;
  editBatchEqual =
    editBatchEqual && p.editBatch.report_number === c.editBatch.report_number;
  editBatchEqual =
    editBatchEqual && p.editBatch.coa_doc_link === c.editBatch.coa_doc_link;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.composition_doc_link === c.editBatch.composition_doc_link;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.radiocarbon_doc_link === c.editBatch.radiocarbon_doc_link;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.calcblend_doc_link === c.editBatch.calcblend_doc_link;
  editBatchEqual =
    editBatchEqual && p.editBatch.rc_doc_link === c.editBatch.rc_doc_link;
  editBatchEqual =
    editBatchEqual && p.editBatch.coq_doc_link === c.editBatch.coq_doc_link;
  editBatchEqual =
    editBatchEqual && p.editBatch.coo_doc_link === c.editBatch.coo_doc_link;
  editBatchEqual =
    editBatchEqual && p.editBatch.tank_release_doc_link === c.editBatch.tank_release_doc_link;  
  editBatchEqual =
    editBatchEqual && p.editBatch.coa_doc_name === c.editBatch.coa_doc_name;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.composition_doc_name === c.editBatch.composition_doc_name;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.radiocarbon_doc_name === c.editBatch.radiocarbon_doc_name;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.calcblend_doc_name === c.editBatch.calcblend_doc_name;
  editBatchEqual =
    editBatchEqual && p.editBatch.rc_doc_name === c.editBatch.rc_doc_name;
  editBatchEqual =
    editBatchEqual && p.editBatch.coq_doc_name === c.editBatch.coq_doc_name;
  editBatchEqual =
    editBatchEqual && p.editBatch.coo_doc_name === c.editBatch.coo_doc_name;
  editBatchEqual =
    editBatchEqual && p.editBatch.tank_release_doc_name === c.editBatch.tank_release_doc_name;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.density_kg_m3_15c === c.editBatch.density_kg_m3_15c;
  editBatchEqual =
    editBatchEqual && p.editBatch.tank_id === c.editBatch.tank_id;
  editBatchEqual =
    editBatchEqual && p.editBatch.location_name === c.editBatch.location_name;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.volume_cubic_meter_15c === c.editBatch.volume_cubic_meter_15c;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.volume_original_scale === c.editBatch.volume_original_scale;
  editBatchEqual = editBatchEqual && p.editBatch.id === c.editBatch.id;
  editBatchEqual = editBatchEqual && p.editBatch.date === c.editBatch.date;
  editBatchEqual =
    editBatchEqual &&
    p.editBatch.subBatches.length === c.editBatch.subBatches.length;

  let subBatchesEqual = true;
  if (p.editBatch.subBatches.length === c.editBatch.subBatches.length) {
    for (let i = 0; i < c.editBatch.subBatches.length; i++) {
      subBatchesEqual =
        subBatchesEqual &&
        areSubBatchesEqual(
          p.editBatch.subBatches[i],
          c.editBatch.subBatches[i]
        );
    }
  }

  return (
    loadedBatchEqual &&
    editEqual &&
    childErrorEqual &&
    fetchErrorEqual &&
    childLoadingEqual &&
    loadingEqual &&
    locationsEqual &&
    childrenEqual &&
    editBatchEqual &&
    subBatchesEqual
  );
};

export default connect(mapStateToProps)(memo(BatchManagement, arePropsEqual));
