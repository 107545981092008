import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadDocuments from '../UploadButton/UploadDocuments';
import {
  getComponentBatchesAsync
} from '../../reducers/thunks/batches.thunks';
import debounce from 'lodash/debounce';

import {
  convertCubicMetersToGallons,
  convertGallonsToCubicMeters
} from '../utils';

const DEBOUNCE_WAIT = 300; // ms

const checkBatchNumError = (batchNum) => {
  if (typeof batchNum === 'string' && batchNum && batchNum.length <= 100) {
    return false;
  }
  return true;
};

const checkVesselNameError = (vesselName) => {
  if (!vesselName) {
    return false;
  }

  if (typeof vesselName === 'string' && vesselName.length > 100) {
    return true;
  }

  return false;
};

const checkValueError = (value) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return true;
  }

  if (value <= 0) {
    return true;
  }

  return false;
};

const getCountryOfOriginById = (batchReducer, country_of_origin_id) => {
  const coo = batchReducer.countriesOfOrigin.filter((coo) => coo.id === country_of_origin_id);
  console.log(`Origin batch coo: ${coo}`);
  if (coo[0] && coo[0].is_eu_member) {
    return coo[0].is_eu_member;
  }
  else {
    return '';
  }
}

function BatchRow(props) {
  const {
    batchReducer,
    key,
    updateBatch,
    removeBatch,
    data,
    index,
    addAttachments,
    useGallons
  } = props;
  const isOriginbatch = data.is_origin_batch;

  const dispatch = useDispatch();
  const updateData = useCallback(
    (partialData) => {
      updateBatch(partialData, index);
    },
    [updateBatch, index]
  );
  const [countryOfOrigin, setCountryOfOrigin] = useState(
    getCountryOfOriginById(batchReducer, data.country_of_origin_id)
  );
  const [batchNumError, setBatchNumError] = useState(
    checkBatchNumError(data.batch_number)
  );
  const [vesselNameError, setVesselNameError] = useState(
    checkVesselNameError(data.vessel_name || '')
  );
  const [volumeError, setVolumeError] = useState(
    checkValueError(data.volume_cubic_meter_15c)
  );
  const [densityError, setDensityError] = useState(
    checkValueError(data.density_kg_m3_15c || 0)
  );

  const tankError = useMemo(() => (data.tank_id ?? -1) < 0, [data.tank_id]);

  const originError = useMemo(() => (data.country_of_origin_id ?? -1) < 0, [data.country_of_origin_id]);

  const [location, setLocation] = useState(
    (data && data.location_name) || undefined
  );

  const changeLocation = (newLoc) => {
    // Clear tank & density selections as those depend
    // on the location
    updateData({
      tank_id: -1,
      density_kg_m3_15c: 0
    });
    setLocation(newLoc);
  };

  const updateVesselName = useRef(
    debounce((newName) => {
      setVesselNameError(checkVesselNameError(newName));
      handleVesselChange(newName);
    }, DEBOUNCE_WAIT)
  );

  const updateVolumeChange = useRef(
    debounce((newVolume) => {
      let vol = parseFloat(newVolume.replace(',', '.'));
      if (isNaN(vol)) {
        vol = 0;
      }
      if (useGallons) {
        vol = convertGallonsToCubicMeters(vol, false, false);
      }

      setVolumeError(checkValueError(vol));
      handleVolumeM3Change(vol);
    }, DEBOUNCE_WAIT)
  );

  const updateBatchNumber = useRef(
    debounce((newBatchNumber) => {
      setBatchNumError(checkBatchNumError(newBatchNumber));
      handleLoadBatchNo(false, newBatchNumber);
    }, DEBOUNCE_WAIT)
  );

  const updateBatchId = (newBatchId) => {
    handleLoadBatchNo(true, newBatchId);
  };

  const [localDensity, setLocalDensity] = useState(data.density_kg_m3_15c || 0);
  const updateDensityChange = useRef(
    debounce((newDensity) => {
      let density = parseFloat(newDensity.replace(',', '.'));
      if (isNaN(density)) {
        density = 0;
      }
      setDensityError(checkValueError(density));
      handleDensityChange(density);
    }, DEBOUNCE_WAIT)
  );
  const updateLocalDensity = (newVal) => {
    setLocalDensity(newVal);
    updateDensityChange.current(newVal);
  };

  const handleVesselChange = (name) => {
    updateData({
      vessel_name: name
    });
  };

  const handleVolumeM3Change = (volume) => {
    updateData({
      volume_cubic_meter_15c: volume,
      volume_original_scale: useGallons ? 'gal' : 'm3_15c'
    });
  };

  const handleLoadBatchNo = (isId, newValue) => {
    const toUpdate = {};

    if (isId) {
      toUpdate.id = newValue;
    } else {
      toUpdate.batch_number = newValue;
      toUpdate.id = undefined;
    }

    updateData(toUpdate);
  };

  const handleDensityChange = (density) => {
    updateData({
      density_kg_m3_15c: density,
      density_original_scale: 'kg_m3_15c'
    });
  };

  const selectedLocation = useMemo(
    () => batchReducer?.locations?.find((l) => l.location_name === location),
    [location, batchReducer.locations]
  );

  const originBatch = useMemo(
    () => batchReducer.origin_batches[index]?.find((ob) => ob.id === data.id),
    [data.id, batchReducer.origin_batches, index]
  );

  useEffect(() => {
    console.log(`Origin batch effect`);
    if (originBatch) {
      console.log(`Origin batch effect: in originBatch`);
      updateData({
        density_kg_m3_15c: originBatch.density_kg_m3_15c,
        composition_doc_link: originBatch.composition_doc_link,
        composition_doc_name: originBatch.composition_doc_link,
        coa_doc_link: originBatch.coa_doc_link,
        coa_doc_name: originBatch.coa_doc_link,
        radiocarbon_doc_link: originBatch.radiocarbon_doc_link,
        radiocarbon_doc_name: originBatch.radiocarbon_doc_link,
        calcblend_doc_link: originBatch.calcblend_doc_link,
        calcblend_doc_name: originBatch.calcblend_doc_link,
        rc_doc_link: originBatch.rc_doc_link,
        rc_doc_name: originBatch.rc_doc_link,
        coq_doc_link: originBatch.coq_doc_link,
        coq_doc_name: originBatch.coq_doc_link,
        coo_doc_link: originBatch.coo_doc_link,
        coo_doc_name: originBatch.coo_doc_link,
        tank_release_doc_link: originBatch.tank_release_doc_link,
        tank_release_doc_name: originBatch.tank_release_doc_link,
        country_of_origin_id: originBatch.country_of_origin_id
      });
    }
  }, [originBatch, updateData]);

  useEffect(() => {
    if (data.is_component_batch && data.tank_id >= 0) {
      if (!batchReducer.originBatchError) {
        // need to check for the error, or we're stuck in an infinite loop trying to reload the component batches
        dispatch(getComponentBatchesAsync(data.tank_id, index));
      } else if (isOriginbatch) {
        dispatch({
          type: 'BATCH_SET_ORIGIN_BATCH_LIST',
          value: { [index]: undefined }
        });
      }
    }
  }, [
    isOriginbatch,
    data.is_component_batch,
    index,
    data.tank_id,
    dispatch,
    batchReducer.originBatchError
  ]);

  const handleTankSelect = (event) => {
    const tankId = event.target.value;
    const selectedTank = selectedLocation.tanks.find((t) => t.id === tankId);
    const toUpdate = {
      tank_id: tankId,
    };
    if (selectedTank.is_origin_tank) {
      toUpdate.is_component_batch = false;
      toUpdate.is_origin_batch = true;
    } else {
      setCountryOfOrigin('');
      toUpdate.is_component_batch = true;
      toUpdate.is_origin_batch = false;
      toUpdate.country_of_origin_id = -1;
    }
    updateData(toUpdate);
  };

  const updateCountryOfOrigin = (is_eu_member) => {
    console.log(`updateCountryOfOrigin(${is_eu_member}) called`);
    setCountryOfOrigin(is_eu_member);
    const country_of_origin = _.find(batchReducer.countriesOfOrigin, {is_eu_member});
    const toUpdate = {
      country_of_origin_id: country_of_origin.id,
    };
    if (isOriginbatch) {
      console.log(`setting toUpdate.country_of_origin_id to ${country_of_origin.id}`);
      toUpdate.country_of_origin_id = country_of_origin.id;
    }
    updateData(toUpdate);
  };

  const removeAttachment = (type) => {
    switch (type) {
    case 'COA':
      updateData({
        coa_doc_name: '',
        coa_doc_link: ''
      });
      break;
    case 'C14':
      updateData({
        radiocarbon_doc_name: '',
        radiocarbon_doc_link: ''
      });
      break;
    case 'COMP':
      updateData({
        composition_doc_name: '',
        composition_doc_link: ''
      });
      break;
    case 'CALCBLEND':
      updateData({
        calcblend_doc_name: '',
        calcblend_doc_link: ''
      });
      break;
    case 'RC':
      updateData({
        rc_doc_name: '',
        rc_doc_link: ''
      });
      break;
    case 'COQ':
      updateData({
        coq_doc_name: '',
        coq_doc_link: ''
      });
      break;
    case 'COO':
      updateData({
        coo_doc_name: '',
        coo_doc_link: ''
      });
      break;
    case 'Tank_release':
      updateData({
        tank_release_doc_name: '',
        tank_release_doc_link: ''
      });
      break;
    default:
      // pass
    }
  };

  return (
    <TableRow
      key={key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Box sx={{ minWidth: 120 }}>
          <UploadDocuments
            type={'component'}
            title={'upload'}
            batch={data}
            submit={(files) => addAttachments(files, true, index)}
            allowUpload={!!isOriginbatch}
            showRemove={!!isOriginbatch}
            removeAttachment={removeAttachment}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth size='small'>
            <InputLabel id={`component_batch-location-label${index}`}>
              Location
            </InputLabel>
            <Select
              labelId={`component_batch-location-label${index}`}
              data-testid={`location-select-sub-${index}`}
              id={`component_batch-location${index}`}
              value={location || ''}
              label='Location'
              error={!location}
              onChange={(e) => changeLocation(e.target.value)}
            >
              {batchReducer.locations &&
                batchReducer.locations
                  .filter((loc) => loc.tanks && loc.tanks.length > 0)
                  .map((loc) => {
                    return (
                      <MenuItem
                        value={loc.location_name}
                        key={loc.location_name}
                      >
                        {loc.location_name}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth size='small'>
            <InputLabel id={`component_batch-tank-label${index}`}>
              Tank/Ship
            </InputLabel>
            <Select
              labelId={`component_batch-tank-label${index}`}
              data-testid={`tankship-select-sub-${index}`}
              id={`component_batch-tank${index}`}
              value={data.tank_id || -1}
              disabled={!location}
              error={tankError}
              label='Tank/Ship'
              onChange={handleTankSelect}
            >
              {location &&
                selectedLocation?.tanks?.map((tank) => {
                  return (
                    <MenuItem value={tank.id} key={tank.id}>
                      {tank.tank_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </TableCell>
      <TableCell align='left'>
        <Box sx={{ minWidth: 150 }}>
          <FormControl fullWidth size='small'>
            <InputLabel htmlFor='country-select'>Country of origin</InputLabel>
            <Select
              labelId={`origin-label${index}`}
              data-testid={`origin-label-${index}`}
              id={`origin-label${index}`}
              label='Country of origin'
              value={countryOfOrigin || -1}
              onChange={(e) => updateCountryOfOrigin(e.target.value)}
              error={originError}
              disabled={!isOriginbatch}
            >
              {batchReducer.countriesOfOrigin.map((c) => (
                <MenuItem value={c.is_eu_member} key={c.id}>
                  {c.is_eu_member}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </TableCell>
      <TableCell>
        <TextField
          id={`component_batch-vessel${index}`}
          label='Vessel'
          variant='outlined'
          size='small'
          error={vesselNameError}
          defaultValue={data.vessel_name || ''}
          onChange={(ev) => updateVesselName.current(ev.target.value)}
          inputProps={{
            maxLength: 100
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`component_batch-volume${index}`}
          sx={{ minWidth: 150 }}
          size='small'
          error={volumeError}
          required
          inputProps={{
            inputMode: 'decimal',
            pattern: '([0-9]+)?[\\.]?[0-9]{0,3}',
            'data-testid': `volume-sub-${index}`
          }}
          variant='outlined'
          defaultValue={
            useGallons
              ? convertCubicMetersToGallons(
                data.volume_cubic_meter_15c || 0,
                true,
                false
              )
              : data.volume_cubic_meter_15c || 0
          }
          onChange={(ev) => updateVolumeChange.current(ev.target.value)}
        />
      </TableCell>
      <TableCell>
        {data.is_component_batch && batchReducer.origin_batches && batchReducer.origin_batches[index] ? (
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size='small'>
              <InputLabel id={`component_batch-number-label${index}`}>
                Batch Number
              </InputLabel>
              <Select
                labelId={`component_batch-number-label${index}`}
                id={`component_batch-number${index}`}
                value={data.id || ''}
                label='Batch Number'
                size='small'
                onChange={(ev) => updateBatchId(ev.target.value)}
              >
                {batchReducer.origin_batches[index].map((batch) => {
                  return (
                    <MenuItem value={batch.id} key={batch.id}>
                      {batch.batch_number}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <TextField
            id={`component_batch-number${index}`}
            label='Batch Number'
            variant='outlined'
            size='small'
            defaultValue={data.batch_number}
            onChange={(ev) => updateBatchNumber.current(ev.target.value)}
            error={batchNumError}
            required
            inputProps={{
              maxLength: 100,
              'data-testid': `batch-number-sub-${index}`
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {data.is_component_batch ? (
          <Box sx={{ paddingLeft: 2 }}>{data.density_kg_m3_15c || 0}</Box>
        ) : (
          <TextField
            id={`component_batch-density${index}`}
            size='small'
            inputProps={{
              inputMode: 'decimal',
              pattern: '([0-9]+)?[\\.]?[0-9]{0,4}',
              'data-testid': `density-sub-${index}`
            }}
            error={densityError}
            variant='outlined'
            // defaultValue={data.density_kg_m3_15c || 0}
            value={localDensity}
            onChange={(ev) => updateLocalDensity(ev.target.value)}
          />
        )}
      </TableCell>
      <TableCell>
        <IconButton
          edge='start'
          sx={{ mr: 2 }}
          onClick={() => {
            removeBatch(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function mapStateToProps(state) {
  const { batch: batchReducer, preferences } = state;
  return {
    batchReducer,
    useGallons: preferences.gallons
  };
}

BatchRow.propTypes = {
  batchReducer: PropTypes.object,
  key: PropTypes.string,
  updateBatch: PropTypes.func.isRequired,
  removeBatch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  addAttachments: PropTypes.func.isRequired,
  useGallons: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(BatchRow);
