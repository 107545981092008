import apiClient from './apiClient';
import 'firebase/compat/auth';

class batchServices {
  // Find batches belonging to a tank
  getBatchesInTank = (tankId, startDate, endDate, batchNumber) =>
    apiClient().get(
      '/v1/batches',
      { params: { 
        tankId: tankId,
        startDate: startDate,
        endDate: endDate,
        batchNumber: batchNumber,      
      } },
      
    );

  // Find an individual batch based on batch id
  getBatchWithBatchId = (batchId, generate_docs) => 
    apiClient().get(
      '/v1/batches/' + batchId,
      generate_docs ? { params: { generateDocs: generate_docs } } : null
    );

  // Find full batch hierarchy tree for a batch
  getBatchHierarchy = (batchId, date) => apiClient().get(
    `/v1/batches/${batchId}/hierarchy`,
    date ? { params: { limitDate: date } } : null 
  );

  // Find all locations, their tanks and tank products
  getLocations = () => apiClient().get('/v1/locations/tanks');

  // Save a new batch
  sendBatch = (data) => apiClient().post('/v1/batches', data);

  // Update an existing batch
  updateBatch = data => apiClient().put('/v1/batches', data);

  // Approve a batch
  approveBatch = (batchId, approvalRequest) =>
    apiClient().put(`/v1/batches/${batchId}/approve`, approvalRequest);

  // Get batch children
  getBatchChildren = (batchId) => apiClient().get(`/v1/batches/${batchId}/children`);

  getBatchSummaryLink = (batches) => apiClient().post('/v1/search_reports', batches);
}

export default new batchServices();
