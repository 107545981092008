import React from 'react';
import PropTypes from 'prop-types';
//import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, connect } from 'react-redux';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function SnackBar(props) {
  const dispatch = useDispatch();
  //const { t } = useTranslation(); use this when translations are more developed
  const { snackBarProps, open } = props;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: 'CLEAR_SNACKBAR' });
  };
  return snackBarProps ? (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={snackBarProps.severity || 'info'}
        sx={{ width: '100%' }}
      >
        {snackBarProps.message}
      </Alert>
    </Snackbar>
  ) : null;
}

function mapStateToProps(state) {
  const { common } = state;
  return {
    snackBarProps: common.snackBarProps,
    open: common.snackBarProps.open,
  };
}

SnackBar.propTypes = {
  snackBarProps: PropTypes.shape({
    severity: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(SnackBar);
