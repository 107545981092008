
export const m3toGallonsMulti = 264.17205235815;
export const gallonsToM3Multi = 0.003785411784;

export const convertCubicMetersToGallons = (volM3, string = false, round = true) => {
  if (typeof volM3 === 'string') {
    volM3 = parseFloat(volM3);
  }
  const volGallon = volM3 * m3toGallonsMulti;
  if (string) {
    return volGallon.toFixed(3);
  }
  if (round) {
    return Math.round(volGallon * 1000) / 1000;
  }
  return volGallon;
};

export const convertGallonsToCubicMeters = (volGallon, string = false, round = true) => {
  if (typeof volGallon === 'string')  {
    volGallon = parseFloat(volGallon);
  }
  const volM3 = volGallon * gallonsToM3Multi;
  if (string) {
    return volM3.toFixed(3);
  }
  if (round) {
    return Math.round(volM3 * 1000) / 1000;
  }
  return volM3;
};
