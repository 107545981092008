import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function DatePicker({ initialDate, onChange, label, error, onKeyPress }) {
  const [date, setDate] = useState(initialDate || null);

  const setNewDate = (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
    setDate(formattedDate);
    if (onChange) {
      onChange(formattedDate);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        format='DD.MM.YYYY'
        label={label ?? 'Date'}
        value={dayjs(date)} // In v6, the format expected by the value prop is the same as for any other prop holding a date
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? true : false} // Set error based on your state
            helperText={error} // Display error message if available
          />
        )}
        onChange={(newDate) => {
          setNewDate(newDate);
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
