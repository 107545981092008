import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import AdminRoute from './components/AdminRoute/AdminRoute';
import BatchManagement from './components/BatchManagement/BatchManagement';
import BatchView from './components/BatchView/BatchView';

const BatchSearch = React.lazy(() =>
  import('./components/BatchSearch/BatchSearch')
);

const UserManagement = React.lazy(() =>
  import('./components/UserManagement/UserManagement')
);

const AuditView         = React.lazy(() => import('./components/AuditView/AuditBundle').then(module => ({ default: module.AuditView })));
const AuditLocation     = React.lazy(() => import('./components/AuditView/AuditBundle').then(module => ({ default: module.AuditLocation })));
const AuditProductType  = React.lazy(() => import('./components/AuditView/AuditBundle').then(module => ({ default: module.AuditProductType })));
const AuditBatch        = React.lazy(() => import('./components/AuditView/AuditBundle').then(module => ({ default: module.AuditBatch })));
const AuditTank         = React.lazy(() => import('./components/AuditView/AuditBundle').then(module => ({ default: module.AuditTank })));

const MasterDataView = React.lazy(() => import('./components/MasterDataView/MasterDataView'));

function RouteSetup({ useGallons }) {
  return (
    <Routes>
      <Route
        exact
        path="/batch-search"
        element={<AuthRoute child={<BatchSearch />} />}
      />
      <Route
        exact
        path="/user-management"
        element={<AuthRoute child={<AdminRoute><UserManagement /></AdminRoute>} />}
      />
      <Route
        path="/batch-creation"
        // Key prop is here to force remounting of the component if the volume display setting changes
        // this way we should avoid odd inbetween states with partially gallons and metric.
        // Plus, this looks nicer to the user at loads the data again than changing their inputs at will.
        element={<AuthRoute child={<BatchManagement key={`${useGallons ? 'gallons' : 'metric'}`}/>} />}
      />
      <Route
        exact
        path="/batch-view/:id"
        element={<AuthRoute child={<BatchView />} />}
      />
      <Route
        path="/batch-view/:id/edit"
        // Same thing here as on /batch-creation
        element={<AuthRoute child={<BatchManagement edit={true} key={`${useGallons ? 'gallons' : 'metric'}`} />}/>}
      />
      <Route
        path="/audit"
        exact
        element={<AuthRoute child={<Navigate replace to="/audit/location" />} />}
      />
      <Route
        path="/audit"
        element={<AuthRoute child={<AuditView />} />}
      >
        <Route path="location" element={<AuditLocation />} />
        <Route path="tank" element={<AuditTank />} />
        <Route path="product" element={<AuditProductType />} />
        <Route path="batch" element={<AuditBatch />} />
      </Route>
      <Route
        path="/data-management"
        element={<AuthRoute child={<AdminRoute><MasterDataView /></AdminRoute>} />}
      />
      <Route
        path="*"
        element={<AuthRoute child={<Navigate replace to="/batch-search" />} />}
      />
    </Routes>
  );
}

const mapStateToProps = ({ preferences }) => {
  return {
    useGallons: preferences.gallons,
  };
};

export default connect(mapStateToProps)(RouteSetup);
