import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import LocationSelect from '../LocationSelect/LocationSelect';
import TankShipSelect from '../TankShipSelect/TankShipSelect';
import DatePicker from '../DatePickerComponent/DatePicker';
import UploadDocuments from '../UploadButton/UploadDocuments';

function TankAndLocationsTable(props) {
  const { batch, updateBatch, addAttachments } = props;

  const checkBatchNumError = (batchNum) => {
    if (typeof batchNum === 'string' && batchNum && batchNum.length <= 100) {
      return false;
    }
    return true;
  };

  const [batchNumError, setBatchNumError] = useState(
    checkBatchNumError(batch.batch_number)
  );
  const tankError = useMemo(() => (batch.tank_id ?? -1) < 0, [batch.tank_id]);
  const documentError = useMemo(
    () => !batch.composition_doc_link && !batch.coa_doc_link,
    [batch.composition_doc_link, batch.coa_doc_link]
  );

  const updateLocation = (loc) => {
    updateBatch({
      location_name: loc,
      tank_id: -1
    });
  };

  const updateTank = (tank) => {
    updateBatch({
      tank_id: tank
    });
  };

  const updateDate = (date) => {
    updateBatch({
      date: date
    });
  };

  const updateReportNumber = (event) => {
    updateBatch({
      report_number: event.target.value
    });
  };

  const updateBatchNumber = (event) => {
    setBatchNumError(checkBatchNumError(event.target.value));
    updateBatch({
      batch_number: event.target.value
    });
  };

  const removeAttachment = (type) => {
    switch (type) {
      case 'COA':
        updateBatch({
          coa_doc_name: '',
          coa_doc_link: ''
        });
        break;
      case 'C14':
        updateBatch({
          radiocarbon_doc_name: '',
          radiocarbon_doc_link: ''
        });
        break;
      case 'COMP':
        updateBatch({
          composition_doc_name: '',
          composition_doc_link: ''
        });
        break;
      case 'CALCBLEND':
        updateBatch({
          calcblend_doc_name: '',
          calcblend_doc_link: ''
        });
        break;
      case 'RC':
        updateBatch({
          rc_doc_name: '',
          rc_doc_link: ''
        });
        break;
      case 'COQ':
        updateBatch({
          coq_doc_name: '',
          coq_doc_link: ''
        });
        break;
      case 'COO':
        updateBatch({
          coo_doc_name: '',
          coo_doc_link: ''
        });
        break;
      case 'Tank_release':
        updateBatch({
          tank_release_doc_name: '',
          tank_release_doc_link: ''
        });
        break;

      default:
      // pass
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead className='column'>
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell align='left'>Tank/Ship</TableCell>
            <TableCell align='left'>Date</TableCell>
            <TableCell align='left'>Batch Number</TableCell>
            <TableCell align='left'>Report Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component='th' scope='row'>
              <LocationSelect
                initialLocation={batch.location_name}
                onChange={updateLocation}
                error={!batch.location_name}
              />
            </TableCell>
            <TableCell align='left'>
              <TankShipSelect
                disabled={!batch.location_name}
                value={batch.tank_id}
                locationName={batch.location_name}
                onChange={updateTank}
                error={tankError}
              />
            </TableCell>
            <TableCell align='left'>
              <DatePicker
                initialDate={batch.date}
                onChange={updateDate}
                error={!batch.date}
              />
            </TableCell>
            <TableCell align='left'>
              <TextField
                id='batch-number'
                value={batch.batch_number ?? ''}
                onChange={updateBatchNumber}
                label='Batch Number'
                size='small'
                variant='outlined'
                error={batchNumError}
                required
                inputProps={{
                  maxLength: 100
                }}
              />
            </TableCell>
            <TableCell align='left'>
              <TextField
                id='report-number'
                value={batch.report_number ?? ''}
                onChange={updateReportNumber}
                label='Report Number'
                size='small'
                variant='outlined'
                inputProps={{
                  maxLength: 100
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <UploadDocuments
        batch={batch}
        submit={(files) => addAttachments(files, false)}
        error={documentError}
        showRemove={true}
        removeAttachment={removeAttachment}
      />
    </TableContainer>
  );
}

TankAndLocationsTable.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  batch: PropTypes.object,
  updateBatch: PropTypes.func,
  addAttachments: PropTypes.func
};

export default TankAndLocationsTable;
