import apiClient from './apiClient';

class attachmentServices {
  //Get a download URL for an attachment file
  getAttachmentAsync = (filePath) => apiClient().get('/v1/attachments/' + encodeURI(filePath));

  //Upload attachment files to Cloud Storage
  addAttachments = (formData) =>
    apiClient().post('/v1/attachments', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

  // Generate a PDF report for a batch and receive a download URL
  generateReport = (report_request) => apiClient().post('/v1/hierarchy_reports', report_request);

  // Generate a PDF report for a batch's country of origin and receive a download URL
  generateOriginReport = (report_request) => apiClient().post('/v1/origin_reports', report_request);
}

export default new attachmentServices();
