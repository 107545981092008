import attachmentServices from '../../services/attachmentServices';

//Get a download URL for an attachment file
export const getAttachmentAsync = (file_path, doc_type) => async (dispatch) => {
  dispatch({ type: 'SET_DOCUMENT_LOADING', value: true });
  await attachmentServices
    .getAttachmentAsync(file_path)
    .then((response) => {
      dispatch({ type: doc_type, value: response.data });
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({ type: 'SET_DOCUMENT_LOADING', value: false });
    });
};

// Upload attachment files to Cloud Storage
export const addAttachmentsAsync = (files, isComponent, index) => async (dispatch, getStore) => {

  const { batch } = getStore().edit;
  const formData = new FormData();

  formData.append('batch_composition_doc', files.composition);
  formData.append('coa_doc', files.coa);
  formData.append('radiocarbon_doc', files.radiocarbon);
  formData.append('calcblend_doc', files.calcblend);
  formData.append('rc_doc', files.rc);
  formData.append('coq_doc', files.coq);
  formData.append('coo_doc', files.coo);
  formData.append('tank_release_doc', files.tankrelease);


  dispatch({ type: 'SET_DOCUMENTS_LOADING', value: true });
  await attachmentServices
    .addAttachments(formData)
    .then((response) => {
      if (isComponent) {
        const subBatch = batch.subBatches[index];
        dispatch({
          type: 'EDIT_UPDATE_SUB_BATCH',
          value: {
            index,
            batch: {
              ...response.data,
              composition_doc_link: response.data.composition_doc_link || subBatch.composition_doc_link || null,
              composition_doc_name: files.composition?.name || subBatch.composition_doc_name || '',
              coa_doc_link: response.data.coa_doc_link || subBatch.coa_doc_link || null,
              coa_doc_name: files.coa?.name || subBatch.coa_doc_name || '',
              radiocarbon_doc_link: response.data.radiocarbon_doc_link || subBatch.radiocarbon_doc_link || null,
              radiocarbon_doc_name: files.radiocarbon?.name || subBatch.radiocarbon_doc_name || '',
              calcblend_doc_link: response.data.calcblend_doc_link || subBatch.calcblend_doc_link || null,
              calcblend_doc_name: files.calcblend?.name || subBatch.calcblend_doc_name || '',
              rc_doc_link: response.data.rc_doc_link || subBatch.rc_doc_link || null,
              rc_doc_name: files.rc?.name || subBatch.rc_doc_name || '',
              coq_doc_link: response.data.coq_doc_link || subBatch.coq_doc_link || null,
              coq_doc_name: files.coq?.name || subBatch.coq_doc_name || '',
              coo_doc_link: response.data.coo_doc_link || subBatch.coo_doc_link || null,
              coo_doc_name: files.coo?.name || subBatch.coo_doc_name || '',
              tank_release_doc_link: response.data.tank_release_doc_link || subBatch.tank_release_doc_link || null,
              tank_release_doc_name: files.tankrelease?.name || subBatch.tank_release_doc_name || ''
            },
          },
        });
      } else {
        dispatch({
          type: 'EDIT_UPDATE_DATA',
          value: {
            composition_doc_link: response.data.composition_doc_link || batch.composition_doc_link || null,
            composition_doc_name: files.composition?.name || batch.composition_doc_name || '',
            coa_doc_link: response.data.coa_doc_link || batch.coa_doc_link || null,
            coa_doc_name: files.coa?.name || batch.coa_doc_name || '',
            radiocarbon_doc_link: response.data.radiocarbon_doc_link || batch.radiocarbon_doc_link || null,
            radiocarbon_doc_name: files.radiocarbon?.name || batch.radiocarbon_doc_name || '',
            calcblend_doc_link: response.data.calcblend_doc_link || batch.calcblend_doc_link || null,
            calcblend_doc_name: files.calcblend?.name || batch.calcblend_doc_name || '',
            rc_doc_link: response.data.rc_doc_link || batch.rc_doc_link || null,
            rc_doc_name: files.rc?.name || batch.rc_doc_name || '',
            coq_doc_link: response.data.coq_doc_link || batch.coq_doc_link || null,
            coq_doc_name: files.coq?.name || batch.coq_doc_name || '',
            coo_doc_link: response.data.coo_doc_link || batch.coo_doc_link || null,
            coo_doc_name: files.coo?.name || batch.coo_doc_name || '',
            tank_release_doc_link: response.data.tank_release_doc_link || batch.tank_release_doc_link || null,
            tank_release_doc_name: files.tankrelease?.name || batch.tank_release_doc_name || '',

          },
        });
      }
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({ type: 'SET_DOCUMENTS_LOADING', value: false });
    });
};

//Generate a PDF report for a batch and receive a download URL
export const generateReportAsync = (batchId, threshold, date) => async (dispatch) => {
  dispatch({ type: 'SET_REPORT_LOADING', value: true });

  //Populate JSON request model for API endpoint
  const report_request = {
    batch_id: batchId,
    report_threshold_percent: threshold,
    start_date: date,
  };

  await attachmentServices
    .generateReport(report_request)
    .then((response) => {
      dispatch({ type: 'SET_REPORT_LINK', value: response.data });
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({ type: 'SET_REPORT_LOADING', value: false });
    });
};

// Generate country of origin report
export const generateOriginReportAsync = (batchId) => async (dispatch) => {
  dispatch({ type: 'SET_ORIGIN_REPORT_LOADING', value: true});

  // Populate JSON request model for API endpoint
  const report_request = {
    batch_id: batchId,
  }

  await attachmentServices
    .generateOriginReport(report_request)
    .then((response) => {
      dispatch({ type: 'SET_ORIGIN_REPORT_LINK', value: response.data });
    })
    .catch(error => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({ type: 'SET_ORIGIN_REPORT_LOADING', value: false });
    });
};
