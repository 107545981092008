import countryOfOriginServices from '../../services/countryOfOriginServices';

// Get all countries of origin
export const getAllCountriesOfOriginAsync = () => async (dispatch) => {
  dispatch({ type: 'SET_COUNTRIESOFORIGIN_LOADING', value: true });
  await countryOfOriginServices
    .getAllCountriesOfOrigin()
    .then((response) => {
      dispatch({
        type: 'SAVE_COUNTRIESOFORIGIN',
        value: response.data
      });
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({
        type: 'SET_COUNTRIESOFORIGIN_LOADING',
        value: false
      });
    });
};
