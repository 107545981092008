import batchServices from '../../services/batchServices';

// Get all countries of origin
export const getAllCountriesOfOriginAsync = () => async (dispatch) => {
  dispatch({ type: 'SET_COUNTRIESOFORIGIN_LOADING', value: true });
  await batchServices
    .getAllCountriesOfOrigin()
    .then((response) => {
      dispatch({
        type: 'SAVE_COUNTRIESOFORIGIN',
        value: response.data
      });
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({
        type: 'SET_COUNTRIESOFORIGIN_LOADING',
        value: false
      });
    });
};

// Get all locations, their tanks and tank products
export const getLocationsAsync = () => async (dispatch) => {
  dispatch({ type: 'BATCH_SET_LOCATIONS_LOADING', value: true });
  await batchServices
    .getLocations()
    .then((response) => {
      dispatch({ type: 'SAVE_LOCATIONS', value: response.data });
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({ type: 'BATCH_SET_LOCATIONS_LOADING', value: false });
    });
};

// Find all batches within a tank
export const getBatchesAsync =
  (tankId, startDate, endDate, batchNumber) => async (dispatch) => {
    dispatch({ type: 'SET_BATCH_LIST_LOADING', value: true });
    await batchServices
      .getBatchesInTank(tankId, startDate, endDate, batchNumber)
      .then((response) => {
        dispatch({ type: 'SET_BATCH_LIST', value: response.data });
      })
      .catch((error) => {
        dispatch({ type: 'LOAD_ERROR' });
      })
      .finally(() => {
        dispatch({ type: 'SET_BATCH_LIST_LOADING', value: false });
      });
  };

export const getComponentBatchesAsync = (tankId, index) => async (dispatch) => {
  dispatch({ type: 'BATCH_SET_ORIGIN_BATCH_LOADING', value: true });
  await batchServices
    .getBatchesInTank(tankId)
    .then((response) => {
      dispatch({
        type: 'BATCH_SET_ORIGIN_BATCH_LIST',
        value: { [index]: response.data }
      });
    })
    .catch((error) => {
      dispatch({ type: 'BATCH_SET_ORIGIN_BATCH_ERROR', value: error.message });
    })
    .finally(() => {
      dispatch({ type: 'BATCH_SET_ORIGIN_BATCH_LOADING', value: false });
    });
};

// Find a single batch with batch id
export const getBatchAsync = (batchId, generate_docs) => async (dispatch) => {
  dispatch({ type: 'SET_BATCH_LOADING', value: true });
  await batchServices
    .getBatchWithBatchId(batchId, generate_docs)
    .then((response) => {
      if (Array.isArray(response.data) && response.data.length > 0) {
        dispatch({ type: 'SET_BATCH_DETAILS', value: response.data[0] });
      } else {
        throw new Error('Invalid batch response');
      }
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
    })
    .finally(() => {
      dispatch({ type: 'SET_BATCH_LOADING', value: false });
    });
};

// Create a batch
export const sendBatchAsync = () => async (dispatch, getStore) => {
  const { batch } = getStore().edit;
  const component_batches = batch.subBatches
    .filter((sb) => sb.is_component_batch)
    .map((cb) => ({
      id: cb.id,
      vessel_name: cb.vessel_name,
      volume_cubic_meter_15c: cb.volume_cubic_meter_15c,
      volume_original_scale: cb.volume_original_scale
    }));

  const origin_batches = batch.subBatches
    .filter((sb) => sb.is_origin_batch)
    .map((ob) => ({
      tank_id: ob.tank_id,
      batch_number: ob.batch_number,
      composition_doc_link: ob.composition_doc_link,
      coa_doc_link: ob.coa_doc_link,
      radiocarbon_doc_link: ob.radiocarbon_doc_link,
      calcblend_doc_link: ob.calcblend_doc_link,
      rc_doc_link: ob.rc_doc_link,
      coq_doc_link: ob.coq_doc_link,
      coo_doc_link: ob.coo_doc_link,
      tank_release_doc_link: ob.tank_release_doc_link,
      vessel_name: ob.vessel_name,
      volume_cubic_meter_15c: ob.volume_cubic_meter_15c,
      volume_original_scale: ob.volume_original_scale,
      density_kg_m3_15c: ob.density_kg_m3_15c,
      density_original_scale: ob.density_original_scale,
      country_of_origin_id: ob.country_of_origin_id
    }));

  const newBatch = {
    batch_number: batch.batch_number,
    report_number: batch.report_number,
    coa_doc_link: batch.coa_doc_link,
    composition_doc_link: batch.composition_doc_link,
    density_kg_m3_15c: batch.density_kg_m3_15c,
    density_original_scale: batch.density_original_scale,
    date: batch.date,
    radiocarbon_doc_link: batch.radiocarbon_doc_link,
    calcblend_doc_link: batch.calcblend_doc_link,
    rc_doc_link: batch.rc_doc_link,
    coq_doc_link: batch.coq_doc_link,
    coo_doc_link: batch.coo_doc_link,
    tank_release_doc_link: batch.tank_release_doc_link,
    tank_id: batch.tank_id,
    volume_cubic_meter_15c: batch.volume_cubic_meter_15c,
    volume_original_scale: batch.volume_original_scale,
    origin_batches,
    component_batches
  };

  dispatch({ type: 'SET_LOADING', value: true });
  await batchServices
    .sendBatch(newBatch)
    .then((response) => {
      dispatch({ type: 'CLEAR_BATCH_DATA' });
      dispatch({ type: 'EDIT_CLEAR' });
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'success',
          message: 'Batch sent successfully'
        }
      });
      dispatch({
        type: 'COMMON_NAVIGATE_TO',
        value: `/batch-view/${response.data.id}`
      });
    })
    .catch((error) => {
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'error',
          message: 'Batch sending failed'
        }
      });
    })
    .finally(() => {
      dispatch({ type: 'SET_LOADING', value: false });
    });
};

// Update an existing batch
export const updateBatchAsync = () => async (dispatch, getStore) => {
  const { batch } = getStore().edit;

  const component_batches = batch.subBatches
    .filter((sb) => sb.is_component_batch)
    .map((cb) => ({
      id: cb.id,
      vessel_name: cb.vessel_name,
      volume_cubic_meter_15c: cb.volume_cubic_meter_15c,
      volume_original_scale: cb.volume_original_scale
    }));

  const origin_batches = batch.subBatches
    .filter((sb) => sb.is_origin_batch)
    .map((ob) => ({
      id: ob.id,
      tank_id: ob.tank_id,
      batch_number: ob.batch_number,
      composition_doc_link: ob.composition_doc_link,
      coa_doc_link: ob.coa_doc_link,
      radiocarbon_doc_link: ob.radiocarbon_doc_link,
      calcblend_doc_link: ob.calcblend_doc_link,
      rc_doc_link: ob.rc_doc_link,
      coq_doc_link: ob.coq_doc_link,
      coo_doc_link: ob.coo_doc_link,
      tank_release_doc_link: ob.tank_release_doc_link,
      vessel_name: ob.vessel_name,
      volume_cubic_meter_15c: ob.volume_cubic_meter_15c,
      volume_original_scale: ob.volume_original_scale,
      density_kg_m3_15c: ob.density_kg_m3_15c,
      density_original_scale: ob.density_original_scale,
      country_of_origin_id: ob.country_of_origin_id
    }));

  const newBatch = {
    id: batch.id,
    tank_id: batch.tank_id,
    date: batch.date,
    batch_number: batch.batch_number,
    report_number: batch.report_number,
    composition_doc_link: batch.composition_doc_link,
    coa_doc_link: batch.coa_doc_link,
    radiocarbon_doc_link: batch.radiocarbon_doc_link,
    calcblend_doc_link: batch.calcblend_doc_link,
    rc_doc_link: batch.rc_doc_link,
    coq_doc_link: batch.coq_doc_link,
    coo_doc_link: batch.coo_doc_link,
    tank_release_doc_link: batch.tank_release_doc_link,
    volume_cubic_meter_15c: batch.volume_cubic_meter_15c,
    volume_original_scale: batch.volume_original_scale,
    density_kg_m3_15c: batch.density_kg_m3_15c,
    density_original_scale: batch.density_original_scale,
    updated_at: batch.updated_at,
    origin_batches,
    component_batches
  };

  dispatch({ type: 'SET_LOADING', value: true });
  await batchServices
    .updateBatch(newBatch)
    .then((response) => {
      dispatch({ type: 'CLEAR_BATCH_DATA' });
      dispatch({ type: 'EDIT_CLEAR' });
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'success',
          message: 'Batch updated successfully'
        }
      });
      dispatch({
        type: 'COMMON_NAVIGATE_TO',
        value: `/batch-view/${response.data.id}`
      });
    })
    .catch((error) => {
      let msg = '';
      const { data } = error.response;
      if (data.message) {
        msg = ` ${data.message}`;
      }
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'error',
          message: `Batch update failed!${msg}`
        }
      });
    })
    .finally(() => {
      dispatch({ type: 'SET_LOADING', value: false });
    });
};

// Get the full hierarchy tree of a batch
export const getBatchHierarchyAsync = (batchId, date) => async (dispatch) => {
  dispatch({ type: 'BATCH_SET_TREE_LOADING', value: true });
  await batchServices
    .getBatchHierarchy(batchId, date)
    .then((response) => {
      dispatch({ type: 'SET_BATCH_TREE', value: response.data });
    })
    .catch((error) => {
      dispatch({ type: 'LOAD_ERROR' });
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'error',
          message: 'Failed to load batch hierarchy!'
        }
      });
    })
    .finally(() => {
      dispatch({ type: 'BATCH_SET_TREE_LOADING', value: false });
    });
};

// Approve a batch
export const approveBatchAsync = (batchId, remarks) => async (dispatch) => {
  dispatch({ type: 'SET_LOADING', value: true });

  // Populate JSON request model for API endpoint
  const approvalRequest = { approval_remarks: remarks };

  await batchServices
    .approveBatch(batchId, approvalRequest)
    .then((response) => {
      dispatch({ type: 'SET_LOADING', value: false });
    })
    .catch((error) => {
      dispatch({ type: 'SET_LOADING', value: false });
      dispatch({ type: 'LOAD_ERROR' });
    });
};

export const getBatchChildrenAsync = (batchId) => async (dispatch) => {
  dispatch({ type: 'EDIT_SET_CHILDREN_LOADING', value: true });
  await batchServices
    .getBatchChildren(batchId)
    .then((response) => {
      if (Array.isArray(response.data)) {
        dispatch({ type: 'EDIT_SET_CHILDREN', value: response.data });
      } else {
        throw new Error(
          'Unexpected response type when fetching batch children!'
        );
      }
    })
    .catch((error) => {
      dispatch({ type: 'EDIT_SET_CHILDREN_ERROR', value: true });
    })
    .finally(() =>
      dispatch({ type: 'EDIT_SET_CHILDREN_LOADING', value: false })
    );
};

export const getBatchSummaryLinkAsync = () => async (dispatch, getStore) => {
  const { batchlist } = getStore().batch;

  const batches = batchlist.map((b) => b.id);

  dispatch({ type: 'BATCH_SET_SUMMARY_LOADING', value: true });
  await batchServices
    .getBatchSummaryLink({ batch_ids: batches })
    .then((response) => {
      dispatch({
        type: 'BATCH_SET_SUMMARY_LINK',
        value: response.data.report_link
      });
    })
    .catch((error) => {
      dispatch({
        type: 'SHOW_SNACKBAR',
        value: {
          severity: 'error',
          message: 'Search summary report generation failed'
        }
      });
    })
    .finally(() =>
      dispatch({ type: 'BATCH_SET_SUMMARY_LOADING', value: false })
    );
};
