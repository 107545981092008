export const initialState = {
  origin_batches: [],
  searchLocation: null, // string
  locations: [],
  batchlist: [],
  // mixing snake_case and camelCase, \o/
  batchDetails: null,
  searchTank: null,
  batchTree: null,
  summaryLink: null,
  summaryLoading: false,
  locationsLoading: false,
  originBatchError: false,
  originBatchErrorMessage: null,
  originBatchLoading: false,
  batchTreeLoading: false,
  // countries of origin
  countriesOfOrigin: [],
  countriesOfOriginLoading: false,
};

const batchReducer = (state = initialState, { type, value }) => {
  switch (type) {
    case 'CLEAR_BATCH_DATA':
      return initialState;
    case 'SET_SEARCH_LOCATION':
      return { ...state, searchLocation: value, searchTank: null };
    case 'SET_SEARCH_TANK':
      return { ...state, searchTank: value };
    case 'SAVE_LOCATIONS':
      return { ...state, locations: value };
    case 'BATCH_SET_LOCATIONS_LOADING':
      return { ...state, locationsLoading: value };
    case 'SET_BATCH_LIST':
      return { ...state, batchlist: value };
    case 'BATCH_SET_ORIGIN_BATCH_LIST':
      return {
        ...state,
        origin_batches: { ...state.origin_batches, ...value }
      };
    case 'BATCH_SET_ORIGIN_BATCH_ERROR':
      return { ...state, originBatchError: true, originBatchErrorMessage: value };
    case 'BATCH_SET_ORIGIN_BATCH_Loading':
      return { ...state, originBatchLoading: value, originBatchError: false };
    case 'SET_BATCH_DETAILS':
      return { ...state, batchDetails: value };
    case 'CLEAR_BATCH_DETAILS':
      return { ...state, batchDetails: null };
    case 'SET_DOCUMENTS':
      return { ...state, documents: value };
    case 'SET_COA_LINK':
      return { ...state, coalink: value };
    case 'SET_CARBON_LINK':
      return { ...state, carbonlink: value };
    case 'SET_COMPOSITION_LINK':
      return { ...state, compositionlink: value };
    case 'SET_CALCBLEND_LINK':
      return { ...state, calcblendlink: value };
    case 'SET_RC_LINK':
      return { ...state, rclink: value };
    case 'SET_COQ_LINK':
      return { ...state, coqlink: value };
    case 'SET_COO_LINK':
      return { ...state, coolink: value };
    case 'SET_TANK_RELEASE_LINK':
      return { ...state, tankreleaselink: value };
    case 'SET_REPORT_LINK':
      return { ...state, reportlink: value };
    case 'SET_BATCH_TREE':
      return { ...state, batchTree: value };
    case 'BATCH_SET_SUMMARY_LINK':
      return { ...state, summaryLink: value };
    case 'BATCH_SET_SUMMARY_LOADING':
      return { ...state, summaryLoading: value };
    case 'BATCH_SET_TREE_LOADING':
      return { ...state, batchTreeLoading: value };
    case 'SAVE_COUNTRIESOFORIGIN':
      return {
        ...state,
        countriesOfOrigin: value
      };
    case 'SET_COUNTRIESOFORIGIN_Loading':
      return {
        ...state,
        countriesOfOriginLoading: value
      };
    case 'LOAD_ERROR':
      return { ...state };
    case 'SET_ORIGIN_REPORT_LINK':
      return { ...state, originReportLink: value };
    default:
      return state;
  }
};

export default batchReducer;
