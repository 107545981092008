import apiClient from './apiClient';
import 'firebase/compat/auth';

class ManualService {
  getManualLink = () => apiClient().get('/v1/usermanual');
  updateManual = (manual) => {
    const formData = new FormData();
    formData.append('user_manual_doc', manual);
    
    return apiClient().put('/v1/usermanual', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
}

export default new ManualService();