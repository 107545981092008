import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';

import GroupIcon from '@mui/icons-material/Group';
import StorageIcon from '@mui/icons-material/Storage';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';

import { getManualLink } from '../../reducers/thunks/manual.thunks';

function NavigationPane({
  user,
  drawerToggle,
  manualLink,
  manualAvailable,
  manualLoading,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  var menuItems = {
    jig_home: {
      route: '/batch-search',
      default: 'Batch search',
      icon: <ManageSearchIcon color="nesteBlue" sx={{ marginRight: 2 }} />,
    },
    jig_audit: {
      route: '/audit',
      default: 'Audit trail',
      icon: <ReceiptLongIcon color="nesteBlue" sx={{ marginRight: 2 }} />,
    },
  };

  //Show all navigation options for admins
  if(user && 'admin' === user.role) {
    menuItems.jig_user_management = {
      route: '/user-management',
      default: 'User management',
      icon: <GroupIcon color="nesteBlue" sx={{ marginRight: 2 }} />,
    };
    menuItems.jig_data_management = {
      route: '/data-management',
      default: 'Master data management',
      icon: <StorageIcon color="nesteBlue" sx={{ marginRight: 2 }} />,
    };
  }

  useEffect(() => {
    if (user?.uid) {
      dispatch(getManualLink());
    }
  }, [user?.uid]); // have the user.uid here, to only do the initial fetch once we have user data for api token

  useEffect(
    () => {
      if (drawerToggle) {
        dispatch(getManualLink());
      }
    },
    [drawerToggle],
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    dispatch({
      type: 'TOGGLE_DRAWER',
      value: open,
    });
  };

  const emailHelp = (_ev) => {
    window.open('mailto:JIG.help@neste.com');
    dispatch({
      type: 'TOGGLE_DRAWER',
      value: false,
    });
  };
  
  const openManual = (_ev) => {
    window.open(manualLink);
    dispatch({
      type: 'TOGGLE_DRAWER',
      value: false,
    });
  };

  return (
    <div className={'jig_drawer'}>
      <Drawer
        anchor="left"
        open={drawerToggle}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ height: '100%', width: 350 }}>
          <Box
            sx={{ width: '100%' }}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {Object.keys(menuItems).map((item, index) => (
                <ListItem
                  button
                  key={item}
                  onClick={() => {
                    navigate(`${menuItems[item].route}`);
                  }}
                >
                  {menuItems[item].icon}
                  <ListItemText
                    sx={{ color: 'black' }}
                    primary={t(item.toString(), menuItems[item].default)}
                  />
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
          <Box
            sx={{ width: '100%' }}
          >
            <Divider />
            <List>
              <ListItem
                button
                onClick={emailHelp}
              >
                <HelpIcon color="nesteBlue" sx={{ marginRight: 2 }} />
                <ListItemText
                  sx={{ color: 'black' }}
                  primary="JIG.help@neste.com"
                />                
              </ListItem>
              <ListItem
                button
                disabled={!manualAvailable}
                onClick={openManual}
              >
                { manualLoading
                  ? <Box sx={{ marginTop: 0.5, marginRight: 2.5 }}><CircularProgress size={20} /></Box>
                  : <InfoIcon color="nesteBlue" sx={{ marginRight: 2 }} /> }
                <ListItemText
                  sx={{ color: 'black' }}
                  primary="User manual"
                />      
              </ListItem>
            </List>
          </Box>
        </Stack>
      </Drawer>
    </div>
  );
}

function mapStateToProps(state) {
  const { user, common } = state;
  return {
    user: user.currentUser,
    drawerToggle: common.drawerToggle,
    manualLink: common.manualLink,
    manualAvailable: common.manualLink && !common.manualError && !common.manualLoading,
    manualLoading: common.manualLoading,
  };
}

export default connect(mapStateToProps)(NavigationPane);
