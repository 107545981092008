import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, ThemeProvider } from '@mui/material';

import locale_en from './translations/en.json';

import NavigationPane from './components/NavigationPane/NavigationPane';
import ApplicationBar from './components/ApplicationBar/ApplicationBar';
import SnackBar from './components/SnackBar/SnackBar';
import RouteSetup from './routes';
import RedirectWrapper from './components/RedirectWrapper/RedirectWrapper';

import jigTheme from './jigTheme';
import './App.css';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: locale_en,
    },
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
        <RedirectWrapper>
          <ThemeProvider theme={jigTheme}>
            <NavigationPane />
            <div className="jig-container">
              <ApplicationBar />
              <SnackBar />
              <React.Suspense fallback={<Stack sx={{ height: 'calc(100vh - 150px)'}} justifyContent="center" alignItems="center"><CircularProgress size={40} /></Stack>}>
                <RouteSetup />
              </React.Suspense>
            </div>
          </ThemeProvider>
        </RedirectWrapper>
      </Router>
    </React.Fragment>
  );
}

export default App;
