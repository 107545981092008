import React, { useEffect } from 'react';
import BatchDetails from '../BatchDetails/BatchDetails';
import SubBatchDetails from '../SubBatchDetails/SubBatchDetails';
import BatchTree from '../BatchTree/BatchTree';
import BatchDocuments from '../BatchDetails/BatchDocuments';
import { useDispatch, connect } from 'react-redux';
import { getBatchAsync } from '../../reducers/thunks/batches.thunks';
import { getAllCountriesOfOriginAsync } from '../../reducers/thunks/countryOfOrigin.thunks';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PropTypes from 'prop-types';
import ViewContainer from '../ViewContainer/ViewContainer';

function BatchView(props) {
  const dispatch = useDispatch();
  const { batchReducer, isBatchLoading } = props;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllCountriesOfOriginAsync());

    dispatch(getBatchAsync(id, true));
    // Reset possible batch tree data from previous batch view
    dispatch({ type: 'SET_BATCH_TREE', value: null });

  }, [dispatch, id]);

  return (
    <ViewContainer title="View Batch">
      <Box sx={{ margin: 1 }}>
        <Button component={RouterLink} to="/batch-search" size="small" color="secondary" startIcon={ <ArrowBackIcon />}>
          Back to search results
        </Button>
      </Box>
      {
        (() => {
          if (isBatchLoading || !batchReducer.batchDetails) {
            return (
              <Box className="jig_login-loading">
                <CircularProgress size={40} />
              </Box>
            );
          } else if (batchReducer.fetchError) {
            return (
              <div>404 not found</div>
            );
          } else {
            return (
              <React.Fragment>
                <BatchDetails batchReducer={batchReducer} />
                <br />
                <BatchDocuments />
                <br />
                <SubBatchDetails batchReducer={batchReducer} />
                <br />
                <BatchTree batch={batchReducer} />
              </React.Fragment>
            );
          }
        })()
      }
    </ViewContainer>
  );
}

BatchView.propTypes = {
  isBatchLoading: PropTypes.bool.isRequired,
  batchReducer: PropTypes.object,
};

function mapStateToProps(state) {
  const { batch, common } = state;
  return {
    batchReducer: batch,
    isBatchLoading: common.isBatchLoading,
  };
}

export default connect(mapStateToProps)(BatchView);
