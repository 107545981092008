//import * as React from 'react';
import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DocumentLink from '../../static/document-link.svg';
import { connect, useDispatch } from 'react-redux';
import './BatchDocuments.css';

function BatchDocuments(props) {
  const dispatch = useDispatch();
  const { batchDetails: batch } = props;

  useEffect(() => {
    return function cleanup() {
      dispatch({ type: 'CLEAR_BATCH_DETAILS' });
    };
  }, [dispatch]);

  return (
    <Stack direction={'row'} spacing={4}>

      {batch && batch.coa_doc_link ? (
        <Box>
          <a
            className="documentLink"
            href={batch.coa_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> CoA
          </a>
        </Box>
      ) : (
        ''
      )}

      {batch && batch.composition_doc_url ? (
        <Box>
          <a
            className="documentLink"
            href={batch.composition_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> Batch Composition
          </a>
        </Box>
      ) : (
        ''
      )}

      {batch && batch.radiocarbon_doc_url ? (
        <Box>
          <a
            className="documentLink"
            href={batch.radiocarbon_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> C14
          </a>
        </Box>
      ) : (
        ''
      )}

      {batch && batch.calcblend_doc_url ? (
        <Box>
          <a
            className="documentLink"
            href={batch.calcblend_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> Statement of calc.blend%
          </a>
        </Box>
      ) : (
        ''
      )}

      {batch && batch.rc_doc_url ? (
        <Box>
          <a
            className="documentLink"
            href={batch.rc_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> Recertification Test
          </a>
        </Box>
      ) : (
        ''
      )}

      {batch && batch.coq_doc_url ? (
        <Box>
          <a
            className="documentLink"
            href={batch.coq_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> CoQ
          </a>
        </Box>
      ) : (
        ''
      )}

      {batch && batch.coo_doc_url ? (
        <Box>
          <a
            className="documentLink"
            href={batch.coo_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> COO
          </a>
        </Box>
      ) : (
        ''
      )}
      
      {batch && batch.tank_release_doc_url ? (
        <Box>
          <a
            className="documentLink"
            href={batch.tank_release_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DocumentLink} alt={''} /> Tank Release
          </a>
        </Box>
      ) : (
        ''
      )}
    </Stack>
  );
}

function mapStateToProps(state) {
  const { batch } = state;
  return {
    batchDetails: batch.batchDetails,
  };
}

export default connect(mapStateToProps)(BatchDocuments);
