export const initialState = {
  snackBarProps: {
    open: false,
  },
  drawerToggle: false,
  fetchError: false,
  isBatchLoading: false,
  isUserlistLoading: false,
  isBatchlistLoading: false,
  isReportLoading: false,
  isOriginReportLoading: false,
  isDocumentsLoading: false,
  isBatchTreeLoading: false,
  isLoading: false,
  navigate: null,
  manualLoading: false,
  manualError: false,
  manualLink: '',
  manualUpdated: 0,
  manualUpdateLoading: false,
  manualUpdateError: false,
};

const commonReducer = (state = initialState, { type, value }) => {
  switch (type) {
  case 'TOGGLE_DRAWER':
    return { ...state, drawerToggle: value };
  case 'SET_LOADING':
    return { ...state, isLoading: value, fetchError: false };
  case 'SET_DOCUMENTS_LOADING':
    return { ...state, isDocumentsLoading: value, fetchError: false };
  case 'SHOW_SNACKBAR':
    return { ...state, snackBarProps: { ...value, open: true } };
  case 'CLEAR_SNACKBAR':
    return { ...state, snackBarProps: { open: false } };
  case 'SET_DOCUMENT_LOADING':
    return { ...state, isDocumentLoading: value, fetchError: false };
  case 'SET_REPORT_LOADING':
    return { ...state, isReportLoading: value };
  case 'SET_ORIGIN_REPORT_LOADING':
    return { ...state, isOriginReportLoading: value };
  case 'SET_BATCH_LIST_LOADING':
    return { ...state, isBatchlistLoading: value, fetchError: false };
  case 'SET_USERS_LOADING':
    return { ...state, isUserlistLoading: value };
  case 'SET_BATCH_LOADING':
    return { ...state, isBatchLoading: value, fetchError: false };
  case 'SET_BATCH_TREE_LOADING':
    return { ...state, isBatchTreeLoading: value };
    // Clear the common fetcherror when retrying any request that may set it to true
  case 'SET_REPORT_LINK':
  case 'SET_ORIGIN_REPORT_LINK':
  case 'SET_IS_LOADING':
    return { ...state, fetchError: false };
  case 'LOAD_ERROR':
    return { ...state, fetchError: true };
  case 'COMMON_NAVIGATE_TO':
    return { ...state, navigate: value };
  case 'COMMON_SET_MANUAL_LOADING':
    return { ...state, manualLoading: value, manualError: value === true ? false : state.manualError };
  case 'COMMON_SET_MANUAL_ERROR':
    return { ...state, manualError: true };
  case 'COMMON_SET_MANUAL_LINK':
    return { ...state, manualLink: value, manualUpdated: Date.now() };
  case 'COMMON_SET_MANUAL_UPDATE_LOADING':
    return {
      ...state,
      manualUpdateLoading: value,
      manualUpdateError: value === true ? false : state.manualUpdateError,
    };
  case 'COMMON_SET_MANUAL_UPDATE_ERROR':
    return {
      ...state,
      manualUpdateError: true,
    };
  case 'COMMON_INVALIDATE_MANUAL':
    return {
      ...state,
      manualUpdated: 0,
    };
  default:
    return state;
  }
};

export default commonReducer;
