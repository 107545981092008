import React, { useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const UserMenu = ({ userName, doLogOut, useGallons }) => {

  const dispatch = useDispatch();

  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);

  const onMenuBtnClick = (ev) => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const toggleGallons = () => {
    dispatch({ type: 'PREFERENCES_SET_GALLONS', value: !useGallons });
  };

  return (
    <React.Fragment>
      <Box>
        <Button
          id="user-button"
          color="secondary"
          variant="text"
          onClick={onMenuBtnClick}
          ref={anchorEl}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-controls={open ? 'user-menu' : undefined}
          endIcon={<AccountCircleIcon />}
        >
          { userName }
        </Button>
        <Menu
          elevation={8}
          anchorEl={anchorEl.current}
          id="user-menu"
          open={open}
          onClose={closeMenu}
          MenuListProps={{
            'aria-labelledby': 'user-button',
          }}
        >
          <MenuItem>
            <FormControlLabel control={<Switch checked={useGallons} onChange={toggleGallons}/>} label="Use gallons"/>
          </MenuItem>
          <MenuItem onClick={doLogOut}>
            <ListItemIcon >
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ paddingLeft: '12px' }}>Log out</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, preferences }) => {
  const { displayName: userName } = user.currentUser;
  const { gallons } = preferences;

  return {
    userName,
    useGallons: gallons,
  };
};

export default connect(mapStateToProps)(UserMenu);