import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { m3toGallonsMulti } from '../utils';

const Volume = ({ volM3, useGallons }) => {
  if (!useGallons) {
    return typeof volM3 === 'string' ? volM3 : volM3.toFixed(3);
  }

  const gallonVol = typeof volM3 === 'string' ? parseFloat(volM3) * m3toGallonsMulti : volM3 * m3toGallonsMulti;

  return gallonVol.toFixed(3);
};

Volume.propTypes = {
  volM3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = ({ preferences }) => {
  const { gallons } = preferences;

  return {
    useGallons: gallons,
  };
};

export default connect(mapStateToProps)(Volume);