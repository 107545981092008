import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AdminRoute = ({ children, isAdmin }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin) {
      navigate('/batch-search');
    }
  }, [isAdmin]);


  if (!isAdmin) {
    return null;
  }

  return children;
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    isAdmin: user.currentUser.role === 'admin',
  };
};

export default connect(mapStateToProps)(AdminRoute);
