import React from 'react';
import ReactDOM from 'react-dom';

import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

import CircularProgress from '@mui/material/CircularProgress';

import { Provider } from 'react-redux';
import createPersistedStore, { createPersistor } from './store';

import App from './App';

const store = createPersistedStore(storage);
const persistor = createPersistor(store);

const rootElement = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<CircularProgress size={40} />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  rootElement,
);
