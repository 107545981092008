import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import Volume from '../Volume/Volume';

function SubBatchDetails({ batchReducer, useGallons }) {
  const { batchDetails: batch } = batchReducer;

  const [totalDensity, setTotalDensity] = useState(0);

  useEffect(() => {
    if (batch) {
      const allBatches = [].concat(...batch.origin_batches, ...batch.component_batches);
      const newTotalVolume = allBatches.reduce((prev, current) => {
        return prev += parseFloat(current.volume_cubic_meter_15c);
      }, 0);

      const newTotalDensity = allBatches.reduce((prev, current) => {
        return prev + parseFloat(current.volume_cubic_meter_15c) * parseFloat(current.density_kg_m3_15c) / newTotalVolume;
      }, 0);

      setTotalDensity(newTotalDensity);
    }
  }, [batch.origin_batches, batch.component_batches, batch]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Source Location</TableCell>
            <TableCell>Source Tank</TableCell>
            <TableCell>Country of origin</TableCell>
            <TableCell>Vessel</TableCell>
            <Tooltip title="You can change your measurement preferences from user menu." arrow placement="top">
              <TableCell>Volume { useGallons ? 'gallons' : <React.Fragment>m<sup>3</sup> @ 15&#8451;</React.Fragment>}</TableCell>
            </Tooltip>
            <TableCell>Load Batch No</TableCell>
            <TableCell>Density <sup>kg</sup> / <sub>m<sup>3</sup></sub> @ 15&#8451;</TableCell>
            <TableCell>Documents</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {batch &&
            batch.origin_batches.map((row, index) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{row.location_name}</TableCell>
                  <TableCell align="left">{row.tank_name}</TableCell>
                  <TableCell align="left">
                    { row.country_of_origin_id ? 
                        batchReducer.countriesOfOrigin.filter((coo) => coo.id === row.country_of_origin_id)[0].is_eu_member :
                        ""
                    } 
                  </TableCell>
                  <TableCell align="left">{row.vessel_name}</TableCell>
                  <TableCell align="left">
                    <Volume volM3={row.volume_cubic_meter_15c} />
                  </TableCell>
                  <TableCell align="left">{row.batch_number}</TableCell>
                  <TableCell align="left">{row.density_kg_m3_15c}</TableCell>
                  <TableCell align="left">
                    { row.coa_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.coa_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CoA { row.composition_doc_url || row.radiocarbon_doc_url || row.calcblend_doc_url || row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null }
                      </a>
                    ) : null}

                    { row.composition_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.composition_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Batch Composition { row.radiocarbon_doc_url || row.calcblend_doc_url || row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null }
                      </a>
                    ) : null}

                    { row.radiocarbon_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.radiocarbon_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        C14 {  row.calcblend_doc_url || row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null }
                      </a>
                    ) : null}

                    { row.calcblend_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.calcblend_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Statement of calc. blend% { row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null }
                      </a>
                    ) : null}

                    { row.rc_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.rc_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Recertification Test { row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null }
                      </a>
                    ) : null}

                    { row.coq_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.coq_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CoQ { row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null }
                      </a>
                    ) : null}

                    { row.coo_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.coo_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        COO { row.tank_release_doc_url ? (<br/>) : null }
                      </a>
                    ) : null}
                    { row.tank_release_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.tank_release_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tank Release
                      </a>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          {batch &&
            batch.component_batches.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{row.location_name}</TableCell>
                  <TableCell align="left">{row.tank_name}</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">{row.vessel_name}</TableCell>
                  <TableCell align="left">
                    <Volume volM3={row.volume_cubic_meter_15c} />
                  </TableCell>
                  <TableCell align="left">{row.batch_number}</TableCell>
                  <TableCell align="left">{row.density_kg_m3_15c}</TableCell>
                  <TableCell align="left">
                    { row.coa_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.coa_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CoA { row.composition_doc_url || row.radiocarbon_doc_url || row.calcblend_doc_url || row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null}
                      </a>
                    ) : null}

                    { row.composition_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.composition_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Batch Composition { row.radiocarbon_doc_url || row.calcblend_doc_url || row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null}
                      </a>
                    ) : null}

                    { row.radiocarbon_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.radiocarbon_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        C14 {  row.calcblend_doc_url || row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url  ? (<br/>) : null}
                      </a>
                    ) : null}

                    { row.calcblend_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.calcblend_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Statement of calc. blend% { row.rc_doc_url || row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null}
                      </a>
                    ) : null}

                    { row.rc_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.rc_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Recertification Test { row.coq_doc_url || row.coo_doc_url || row.tank_release_doc_url  ? (<br/>) : null}
                      </a>
                    ) : null}

                    { row.coq_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.coq_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CoQ { row.coo_doc_url || row.tank_release_doc_url ? (<br/>) : null}
                      </a>
                    ) : null}

                    { row.coo_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.coo_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        COO { row.tank_release_doc_url ? (<br/>) : null}
                      </a>
                    ) : null}
                    { row.tank_release_doc_url ? (
                      <a
                        className="documentLink"
                        href={row.tank_release_doc_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tank Release
                      </a>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow className="batchCompositionTotal">
            <TableCell className="batchCompText">Calculated Batch Composition</TableCell>
            <TableCell/>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell
              sx={{
                backgroundColor: batch && Math.round(totalDensity * 10000) !== Math.round(batch.density_kg_m3_15c * 10000) ? 'orange !important' : undefined,
              }}
            >
              { totalDensity.toFixed(4) }
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
        <TableFooter>
          <TableRow className="batchCompositionTotal">
            <TableCell className="batchCompText">Saved Batch Composition</TableCell>
            <TableCell />
            <TableCell />
            <TableCell>
              { batch &&
                <Volume volM3={batch.volume_cubic_meter_15c} /> }
            </TableCell>
            <TableCell />
            <TableCell>
              { batch && batch.density_kg_m3_15c }
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

function mapStateToProps(state) {
  const { batch, preferences } = state;
  return { batch, useGallons: preferences.gallons };
}

export default connect(mapStateToProps)(SubBatchDetails);
