import moment from 'moment';

const initialState = {
  locations: [],
  tanks: [],
  products: [],
  batches: [],
  auditLoading: false,
  auditError: false,
};

const auditReducer = (state = initialState, {type, value}) => {
  switch (type) {
  case 'AUDIT_SET_LOCATIONS':
    return {
      ...state,
      locations: cleanLocations(value),
    };
  case 'AUDIT_CLEAR_LOCATIONS':
    return {
      ...state,
      locations: [],
    };
  case 'AUDIT_SET_TANKS':
    return {
      ...state,
      tanks: cleanTanks(value),
    };
  case 'AUDIT_CLEAR_TANKS':
    return {
      ...state,
      tanks: [],
    };
  case 'AUDIT_SET_PRODUCTS':
    return {
      ...state,
      products: cleanProducts(value),
    };
  case 'AUDIT_CLEAR_PRODUCTS':
    return {
      ...state,
      products: [],
    };
  case 'AUDIT_SET_BATCHES':
    return {
      ...state,
      batches: cleanBatches(value),
    };
  case 'AUDIT_CLEAR_BATCHES':
    return {
      ...state,
      batches: [],
    };
  case 'AUDIT_SET_LOADING':
    return {
      ...state,
      auditLoading: value,
      auditError: value === true ? false : state.auditError,
    };
  case 'AUDIT_SET_ERROR':
    return {
      ...state,
      auditError: true,
    };
  default:
    return state;
  }
};

const cleanLocations = (locations) => {
  const newLocations = locations.map((location) => {

    // generate an actually unique id betweem versions for the datagrid component to use
    location.versions = location.versions.map(
      ({id, ...l}) => ({ ...l, location_id: id, id: `${id}-${l.version}`}),
    );
    
    // Sort versions so the newest one is on top
    location.versions.sort((a, b) => {
      return moment(b.updated_at).diff(a.updated_at);
    });

    return location;
  });

  return newLocations;
};

const cleanTanks = (tanks) => {
  const newTanks = tanks.map((tank) => {

    tank.versions = tank.versions.map(
      ({id, ...t}) => ({ ...t, tank_id: id, id: `${id}-${t.version}`}),
    );

    tank.versions.sort((a, b) => {
      return moment(b.updated_at).diff(a.updated_at);
    });

    return tank;
  });

  return newTanks;
};

const cleanProducts = (products) => {
  const newProducts = products.map((product) => {

    product.versions = product.versions.map(
      ({id, ...p}) => ({ ...p, product_id: id, id: `${id}-${p.version}`}),
    );

    product.versions.sort((a, b) => {
      return moment(b.updated_at).diff(a.updated_at);
    });

    return product;
  });

  return newProducts;
};

const cleanBatches = (batches) => {
  let newBatches = batches.map((batch) => {
    batch.versions.batches = batch.versions.batches.map(
      ({id, ...b}) => ({ ...b, batch_id: id, id: `${id}-${b.version}`}),
    );

    batch.versions.batches.sort((a, b) => {
      return moment(b.updated_at).diff(a.updated_at);
    });
    
    return batch;
  });

  const findBatchNumber = (batchId) => {
    const found = newBatches.find(b => b.id === batchId);
    if (found) {
      return `${batchId} (${found.versions.batches[0].batch_number})`;
    }

    return batchId;
  };
  
  newBatches = batches.map((batch) => {
    batch.versions.batch_relations = batch.versions.batch_relations.map(
      (br) => ({
        ...br,
        id: `${br.main_batch_id}-${br.component_batch_id}-${br.version}`,
        main_batch_id: findBatchNumber(br.main_batch_id),
        component_batch_id: findBatchNumber(br.component_batch_id),
      }),
    );
      
    batch.versions.batch_relations.sort((a, b) => {
      return moment(b.updated_at).diff(a.updated_at);
    });
    
    return batch;
  });

  return newBatches;
};

export default auditReducer;
