export const initialState = {
  gallons: false,
};

const userPreferencesReducer = (state = initialState, { type, value}) => {
  switch(type) {
  case 'PREFERENCES_SET_GALLONS':
    return { ...state, gallons: value };
  case 'USER_LOG_OUT':
    return initialState;
  default:
    return state;
  }
};

export default userPreferencesReducer;
